import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {DataService} from '../services/data.service';
import { CompaniesService } from '../services/companies.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class VersionResolver implements Resolve<any> {
  constructor(private dataService: DataService) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (this.dataService.version != '') {
      return this.dataService.version;
    }
    else {
      return this.dataService.getVersion();
    }
  }
}

@Injectable()
export class RegionsResolver implements Resolve<any> {
  constructor(private dataService: DataService) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (this.dataService.regions.length == 0) {
      return this.dataService.getRegions();
    }
    else {
      return this.dataService.regions;
    }
  }
}

@Injectable()
export class DashboardResolver implements Resolve<any> {
  constructor(private service: CompaniesService, private authenticationService: AuthenticationService) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (this.authenticationService.isLoggedIn) {
      return this.service.getDashboard({expiredInDeadlines: 10, expiredInPlanners: 10, expiredInProjects: 10, expiredInTasks: 10});
    }

    return null;
  }
}


