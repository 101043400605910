<router-outlet></router-outlet>
<ngx-ui-loader fgsColor="white" pbColor="white"></ngx-ui-loader>
<modal [modalId]="'api-error-popup'" [isPopup]="true" [popupIcon]="ePopupIcon.error"
       [popupMessage]="'Errore SERVER!' | translate">
  <div popup-footer>
    <div class="popup-generic-error">{{apiError | translate}}</div>
    <custom-button class="button-size" cssType="btn-primary" [width]="eCustomButtonWidth.half" text="OK" (onClick)="hidePopupError()"></custom-button>
  </div>
</modal>

<modal [modalId]="'general-error-popup'" [isPopup]="true" [popupIcon]="generalError.type"
       [popupMessage]="generalError.title | translate">
  <div popup-footer>
    <div class="popup-generic-error">{{generalError.description | translate}}</div>
    <custom-button class="button-size" cssType="btn-primary" [width]="eCustomButtonWidth.half" text="OK" (onClick)="hideGeneralError()"></custom-button>
  </div>
</modal>

<modal [modalId]="'general-question-popup'" [isPopup]="true" [popupIcon]="generalError.type"
       [popupMessage]="generalError.title | translate">
  <div popup-footer>
    <div class="popup-generic-error">{{generalError.description | translate}}</div>
    <custom-button cssType="btn-primary"
                   [text]="generalError.btnOKText ? generalError.btnOKText : 'OK'" css="w-[100px]"
                   (onClick)="generalQuestionClick(true)"></custom-button>
    <custom-button cssType="btn-outline-black w-[100px]" css="ml-3 w-[100px]"
                   [text]="generalError.btnCancelText ? generalError.btnCancelText : 'Cancel'"
                   (onClick)="generalQuestionClick(false)"></custom-button>
  </div>
</modal>
