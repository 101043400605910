import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {IResponse} from '../models/response.model';
import {
  IActivity,
  IAddress,
  ICompany,
  IContact,
  ICourse,
  IDashboard,
  IDashboardRequest,
  IDeadline,
  IDescription, IJob,
  IPlanner,
  IProject,
  ITask,
  ITraining,
  IUser
} from '../models/data.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {ITableFilter, ITableSortSelected} from "../../custom-component-lib/classes/interfaces";
import {eTableSortStatus} from "../../custom-component-lib/classes/enums";
import {CustomersService} from "./customers.service";
import {EventService} from "./events.service";
import {IRequestCourseUser, IRequestTraining} from "../models/requests.model";

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  itemsPerPage: number = environment.itemsPerPage;
  companies: ICompany[] = [];
  selectedCompany: ICompany;
  selectedCompanyUUID: string = '';
  allCategories: IDescription[] = [];

  private baseUrl = `${environment.apiUrl}/companies`

  constructor(private httpClient: HttpClient,
              private authenticationService: AuthenticationService,
              private customerService: CustomersService,
              private eventService: EventService) {

    this.getCompanyFromLocalStorage();
  }

  getParams(page: number = 1, perPage: number = environment.itemsPerPage, sort: ITableSortSelected, filter: ITableFilter = null, list: boolean = false) {
    return this.authenticationService.getParams(page, perPage, sort, filter, list);
  }

  setCompanyInfos(company: ICompany) {
    this.selectedCompany = company;
    this.selectedCompanyUUID = company?.uuid;
    this.customerService.setCompanyInfos(company);

    localStorage.setItem('current-company', JSON.stringify(this.selectedCompany));

    this.eventService.onCompanySelectedChanged(this.selectedCompany)
  }

  private getCompanyFromLocalStorage() {
    if (this.authenticationService.isLoggedIn) {
      if (localStorage.getItem('current-company')) {
        this.selectedCompany = JSON.parse(localStorage.getItem('current-company'));
        this.selectedCompanyUUID = this.selectedCompany.uuid;
        this.customerService.setCompanyInfos(this.selectedCompany);
      }
    }
  }

  getDashboard(param?: IDashboardRequest) {
    let params = new HttpParams();
    if (param) {
      params = params.append('expiredInDeadlines', Number(param.expiredInDeadlines));
      params = params.append('expiredInPlanners', Number(param.expiredInPlanners));
      params = params.append('expiredInProjects', Number(param.expiredInProjects));
      params = params.append('expiredInTasks', Number(param.expiredInTasks));
      params = params.append('expiredFrom', environment.dashboardExpiredFrom);
    }

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/dashboard', {params: params});
  }

  //Companies
  getCompanies(page: number = 1, perPage: number = this.itemsPerPage,
               sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
     let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl, {params: params}).pipe(map(response => {
      let res = new IResponse().deserialize(response);

      if (res.status) {
        this.companies = <ICompany[]>res.data;
      }

      return res;
    }));
  }

  getCompany() {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID);
  }

  updateCompany(data: ICompany, isNew: boolean) {
    let body = {code: data.code, name: data.name, vat: data.vat, category: data.category, activity: data.activity, note: data.note};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID, body).pipe(map(response => {
        return new IResponse().deserialize(response);
      }));
    }
    else {
      return this.httpClient.post(this.baseUrl, body).pipe(map(response => {
        return new IResponse().deserialize(response);
      }));
    }
  }

  deleteCompany() {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID);
  }

//Addresses
  getCompanyAddresses(page: number = 1, perPage: number = this.itemsPerPage,
                      sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/addresses', {params: params});
  }

  getCompanyAddress(address_id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/addresses/' + address_id);
  }

  updateCompanyAddress(data: IAddress, isNew: boolean) {
    // let body = {name: data.name, address: data.address, place: data.place, city: data.city};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/addresses/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/addresses', data);
    }
  }

  deleteCompanyAddress(address_id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/addresses/' + address_id);
  }

  //Contacts
  getCompanyContacts(page: number = 1, perPage: number = this.itemsPerPage,
                     sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/contacts', {params: params});
  }

  getCompanyContact(contact_id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/contacts/' + contact_id);
  }

  updateCompanyContact(data: IContact, isNew: boolean) {
    let body = {name: data.name, email: data.email, phone: data.phone, mobile: data.mobile, fax: data.fax, web: data.web};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/contacts/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/contacts', body);
    }
  }

  deleteCompanyContact(contact_id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/contacts/' + contact_id);
  }

  //Categories
  getCompanyCategories(page: number = 1, perPage: number = this.itemsPerPage, all: boolean = false,
                       sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/categories', {params: params}).pipe(map(response => {
      let res = new IResponse().deserialize(response);

      if (all) {
        this.allCategories = [];
        if (res.status) {
          this.allCategories = <IDescription[]>res.data;
        }
      }

      return res;
    }));
  }

  getCompanyCategory(category_id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/categories/' + category_id);
  }

  updateCompanyCategory(data: IDescription, isNew: boolean) {
    let body = {name: data.name, description: data.description};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/categories/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/categories', body);
    }
  }

  deleteCompanyCategory(category_id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/categories/' + category_id);
  }

  //Customer Statuses
  getCompanyCustomerStatuses(page: number = 1, perPage: number = this.itemsPerPage,
                             sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/customer-status', {params: params});
  }

  getCompanyCustomerStatus(customer_status_id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/customer-status/' + customer_status_id);
  }

  updateCompanyCustomerStatus(data: IDescription, isNew: boolean) {
    let body = {name: data.name, description: data.description};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/customer-status/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/customer-status', body);
    }
  }

  deleteCompanyCustomerStatus(customer_status_id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/customer-status/' + customer_status_id);
  }

  //Qualifications
  getCompanyQualifications(page: number = 1, perPage: number = this.itemsPerPage,
                           sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/qualifications', {params: params});
  }

  getCompanyQualification(qualification_id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/qualifications/' + qualification_id);
  }

  updateCompanyQualification(data: IDescription, isNew: boolean) {
    let body = {name: data.name, description: data.description};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/qualifications/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/qualifications', body);
    }
  }

  deleteCompanyQualification(qualification_id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/qualifications/' + qualification_id);
  }

  //Activity Types
  getCompanyActivityTypes(page: number = 1, perPage: number = this.itemsPerPage,
                          sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/activityTypes', {params: params});
  }

  getCompanyActivityType(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/activityTypes/' + id);
  }

  updateCompanyActivityType(data: IDescription, isNew: boolean) {
    let body = {name: data.name, description: data.description};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/activityTypes/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/activityTypes', body);
    }
  }

  deleteCompanyActivityType(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/activityTypes/' + id);
  }

  //Expiry Types
  getCompanyExpiryTypes(page: number = 1, perPage: number = this.itemsPerPage,
                          sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/expiryTypes', {params: params});
  }

  getCompanyExpiryType(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/expiryTypes/' + id);
  }

  updateCompanyExpiryType(data: IDescription, isNew: boolean) {
    let body = {name: data.name, description: data.description};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/expiryTypes/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/expiryTypes', body);
    }
  }

  deleteCompanyExpiryType(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/expiryTypes/' + id);
  }

  //Users
  getCompanyUsers(page: number = 1, perPage: number = this.itemsPerPage,
                  sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/users', {params: params});
  }

  getCompanyUser(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/users/' + id);
  }

  updateCompanyUser(data: IUser, isNew: boolean) {
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/users/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/users', data);
    }
  }

  deleteCompanyUser(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/users/' + id);
  }

  //Order Types
  getCompanyOrderTypes(page: number = 1, perPage: number = this.itemsPerPage,
                       sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/jobTypes', {params: params});
  }

  getCompanyOrderType(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/jobTypes/' + id);
  }

  updateCompanyOrderType(data: IDescription, isNew: boolean) {
    let body = {name: data.name, description: data.description};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/jobTypes/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/jobTypes', body);
    }
  }

  deleteCompanyOrderType(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/jobTypes/' + id);
  }

  //Order Assignments Type
  getCompanyOrderAssignmentsType(page: number = 1, perPage: number = this.itemsPerPage,
                       sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/assignmentTypes', {params: params});
  }

  getCompanyOrderAssignmentType(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/assignmentTypes/' + id);
  }

  updateCompanyOrderAssignmentType(data: IDescription, isNew: boolean) {
    let body = {name: data.name, description: data.description};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/assignmentTypes/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/assignmentTypes', body);
    }
  }

  deleteCompanyOrderAssignmentType(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/assignmentTypes/' + id);
  }

  //Order Details Type
  getCompanyOrderDetailsType(page: number = 1, perPage: number = this.itemsPerPage,
                                 sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/detailTypes', {params: params});
  }

  getCompanyOrderDetailType(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/detailTypes/' + id);
  }

  updateCompanyOrderDetailType(data: IDescription, isNew: boolean) {
    let body = {name: data.name, description: data.description};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/detailTypes/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/detailTypes', body);
    }
  }

  deleteCompanyOrderDetailType(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/detailTypes/' + id);
  }

  //Course
  getCompanyCourses(page: number = 1, perPage: number = this.itemsPerPage,
                                sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/courses', {params: params});
  }

  getCompanyCourse(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/courses/' + id);
  }

  updateCompanyCourse(data: ICourse, isNew: boolean) {
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/courses/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/courses', data);
    }
  }

  deleteCompanyCourse(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/courses/' + id);
  }

  //Course Training Type
  getCompanyCourseTrainingTypes(page: number = 1, perPage: number = this.itemsPerPage,
                             sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainingTypes', {params: params});
  }

  getCompanyCourseTrainingType(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainingTypes/' + id);
  }

  updateCompanyCourseTrainingType(data: IDescription, isNew: boolean) {
    let body = {name: data.name, description: data.description};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainingTypes/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainingTypes', body);
    }
  }

  deleteCompanyCourseTrainingType(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainingTypes/' + id);
  }

  //Course Type
  getCompanyCourseTypes(page: number = 1, perPage: number = this.itemsPerPage,
                                sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/courseTypes', {params: params});
  }

  getCompanyCourseType(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/courseTypes/' + id);
  }

  updateCompanyCourseType(data: IDescription, isNew: boolean) {
    let body = {name: data.name, description: data.description};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/courseTypes/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/courseTypes', body);
    }
  }

  deleteCompanyCourseType(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/courseTypes/' + id);
  }

  //Course Mode
  getCompanyCourseModes(page: number = 1, perPage: number = this.itemsPerPage,
                        sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/courseModes', {params: params});
  }

  getCompanyCourseMode(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/courseModes/' + id);
  }

  updateCompanyCourseMode(data: IDescription, isNew: boolean) {
    let body = {name: data.name, description: data.description};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/courseModes/' + data.uuid, body);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/courseModes', body);
    }
  }

  deleteCompanyCourseMode(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/courseModes/' + id);
  }

  //Course Location
  // getCompanyCourseLocations(page: number = 1, perPage: number = 1000,
  //                       sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
  //   let params = this.getParams(page, perPage, sort, filter);
  //
  //   return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/expiryTypes', {params: params});
  // }
  //
  // getCompanyCourseLocation(id: string) {
  //   return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/expiryTypes/' + id);
  // }
  //
  // updateCompanyCourseLocation(data: IDescription, isNew: boolean) {
  //   let body = {name: data.name, description: data.description};
  //   if (!isNew) {
  //     return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/expiryTypes/' + data.uuid, body);
  //   }
  //   else {
  //     return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/expiryTypes', body);
  //   }
  // }
  //
  // deleteCompanyCourseLocation(id: string) {
  //   return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/expiryTypes/' + id);
  // }

  getCompanyTrainings(customer_uuid: string = null, course_uuid: string = null, page: number = 1, perPage: number = this.itemsPerPage,
                      sort: ITableSortSelected = { key: 'expired_at', status: eTableSortStatus.desc },
                      filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    if (customer_uuid != null) {
      params = params.append('customer_id', customer_uuid);
    }
    else if (course_uuid != null) {
      params = params.append('course_id', course_uuid);
    }

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainings', {params: params});
  }

  getCompanyTraining(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainings/' + id);
  }

  updateCompanyTraining(data: IRequestTraining, isNew: boolean) {
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainings/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainings', data);
    }
  }

  deleteCompanyTraining(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainings/' + id);
  }

  //Orders - Projects
  getCompanyProjects(page: number = 1, perPage: number = this.itemsPerPage,
                             sort: ITableSortSelected = {key: 'customer->name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects',{params: params});
  }

  getCompanyProject(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + id);
  }

  updateCompanyProject(data: IProject, isNew: boolean) {
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects', data);
    }
  }

  deleteCompanyProject(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + id);
  }

  //Orders - Project->Tasks
  getCompanyProjectTasks(project_id: string, page: number = 1, perPage: number = this.itemsPerPage,
                                 sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id + '/tasks', {params: params});
  }

  getCompanyProjectTask(project_id: string, id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id + '/tasks/' + id);
  }

  updateCompanyProjectTask(project_id: string, data: ITask, isNew: boolean) {
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id + '/tasks/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id + '/tasks', data);
    }
  }

  deleteCompanyProjectTask(project_id: string, id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id + '/tasks/' + id);
  }

  //Orders - Project->Deadlines
  getCompanyProjectDeadlines(project_id: string, page: number = 1, perPage: number = this.itemsPerPage,
    sort: ITableSortSelected = {key: 'description', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id +
      '/deadlines', {params: params});
  }

  getCompanyProjectDeadline(project_id: string, id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id +
      '/deadlines/' + id);
  }

  updateCompanyProjectDeadline(project_id: string, data: IDeadline, isNew: boolean) {
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id +
       '/deadlines/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' +
        this.selectedCompanyUUID + '/projects/' + project_id + '/deadlines', data);
    }
  }

  deleteCompanyProjectDeadline(project_id: string, id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id +
      '/deadlines/' + id);
  }

  //Orders - Project->Task-Jobs
  getCompanyProjectTaskJobs(project_id: string, task_id: string, page: number = 1, perPage: number = this.itemsPerPage,
                            sort: ITableSortSelected = {key: 'description', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id +
      '/tasks/' + task_id + '/jobs', {params: params});
  }

  getCompanyProjectTaskJob(project_id: string, task_id: string, id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id +
      '/tasks/' + task_id + '/jobs/' + id);
  }

  updateCompanyProjectTaskJob(project_id: string, task_id: string, data: IJob, isNew: boolean) {
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id +
        '/tasks/' + task_id + '/jobs/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' +
        this.selectedCompanyUUID + '/projects/' + project_id + '/tasks/' + task_id + '/jobs', data);
    }
  }

  deleteCompanyProjectTaskJob(project_id: string, task_id: string, id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/projects/' + project_id +
      '/tasks/' + task_id + '/jobs/' + id);
  }

  //Activities
  getCompanyActivities(page: number = 1, perPage: number = this.itemsPerPage,
                     sort: ITableSortSelected = {key: 'occurred_at', status: eTableSortStatus.desc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/activities',{params: params});
  }

  getCompanyActivity(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/activities/' + id);
  }

  updateCompanyActivity(data: IActivity, isNew: boolean) {
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/activities/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/activities', data);
    }
  }

  deleteCompanyActivity(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/activities/' + id);
  }

  //Courses - Users
  getCourseUsersExpired(data: IRequestCourseUser) {
    return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainings/expired', data);
  }

  enrollCourseUsers(data: any) {
    return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainings/enroll', data);
  }

  getCourseUsersEnrolled(data: IRequestCourseUser) {
    return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainings/enrolled', data);
  }

  completeCourseUsers(data: any) {
    return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/trainings/completed', data);
  }

  //Planners
  getCompanyPlanners(page: number = 1, perPage: number = this.itemsPerPage, list: boolean = false,
    sort: ITableSortSelected = {key: 'occurred_at', status: eTableSortStatus.desc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter, list);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/planners', {params: params});
  }

  getCompanyPlanner(id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/planners/' + id);
  }

  updateCompanyPlanner(data: IPlanner, isNew: boolean) {
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/planners/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/planners', data);
    }
  }

  deleteCompanyPlanner(id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/planners/' + id);
  }

}
