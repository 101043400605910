import { Component, OnInit } from '@angular/core';
import {ITab} from "../../../../custom-component-lib/classes/interfaces";

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}
