import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router, Event, NavigationEnd} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {Events, EventService} from '../../services/events.service';
import {toggleModal} from '../../../custom-component-lib/classes/shared-functions';
import {IDetailFormConfiguration, IDetailsFormDataUpdate, ITableConfiguration} from '../../../custom-component-lib/classes/interfaces';
import {eCustomButtonWidth, eDetailFormInputType, eDetailFormType, eTableItemType} from '../../../custom-component-lib/classes/enums';
import {first} from 'rxjs/operators';
import {CompaniesService} from '../../services/companies.service';
import {ICompany, IUser} from '../../models/data.model';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ModalLoginComponent} from "../../../custom-component-lib/modal-login/modal-login.component";
import {ModalRegisterComponent} from "../../../custom-component-lib/modal-register/modal-register.component";
import {IRequestRegister} from "../../models/requests.model";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  isLoggedIn: boolean = false;
  loginConfiguration: IDetailFormConfiguration[] = [];
  registerConfigurations: IDetailFormConfiguration[] = [];
  forgottenPasswordConfigurations: IDetailFormConfiguration[] = [];

  subscriptions: Subscription[] = [];
  mobileMenuIsOpen: boolean = false;
  profileMenuIsOpen: boolean = false;
  userInfo: string = '';
  companyInfo: ICompany;
  userPermissions: any;
  forgottenPwdEmail: string = '';
  user: IUser;
  authorized: boolean = false;

  eCustomButtonWidth = eCustomButtonWidth;

  @ViewChild(ModalLoginComponent) modalLoginComponent: ModalLoginComponent;
  @ViewChild(ModalRegisterComponent) modalRegisterComponent: ModalRegisterComponent;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private companiesService: CompaniesService,
              private eventService: EventService,
              private trans: TranslateService) {
  }

  ngOnInit(): void {
    this.attachEvent();
  }

  ngAfterViewInit() {
    this.setUserInfo();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private attachEvent() {
    this.subscribeRoutes();

    this.subscriptions.push(this.eventService.on(Events.translationsLoaded, (value) => {
      // this.initConfigurations();
    }));

    this.subscriptions.push(this.eventService.on(Events.loginStateChanged, (value) => {
      this.isLoggedIn = value;

      if (this.isLoggedIn) {
        // if (this.companiesService.selectedCompanyUUID == '') {
        //   this.loadCompanies(1);
        // }
        this.setUserInfo();
      }
    }));

    this.subscriptions.push(this.eventService.on(Events.userProfileChanged, (value) => {
      this.setUserInfo();
    }));

    this.isLoggedIn = this.authenticationService.isLoggedIn;
    if (this.isLoggedIn) {
      if (this.companiesService.selectedCompanyUUID == '') {
        this.loadCompanies(1);
      }
      this.setUserInfo();
    }

    this.initConfigurations();
  }

  private subscribeRoutes() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.mobileMenuIsOpen = false;
      }
    });
  }

  private setUserInfo() {
    this.userInfo = '';
    this.companyInfo = null;
    if (this.isLoggedIn) {
      this.user = this.authenticationService.currentUserValue.user;
      this.userInfo = this.user.name;
      this.userPermissions = this.user.permissions;
      this.companyInfo = this.companiesService.selectedCompany;
      this.authorized = this.companyInfo != null;
    }
  }

  private initConfigurations() {
    this.loginConfiguration = [{key: 'email', label: 'Email', type: eDetailFormType.input, inputType: eDetailFormInputType.email, validationRequired: true, fullRow: true},
      {key: 'password', label: 'Password', type: eDetailFormType.input, inputType: eDetailFormInputType.password, validationRequired: true, fullRow: true}];
  }

  private loadCompanies(page: number) {
    this.companiesService.getCompanies(page, this.companiesPerPage).subscribe(
      response => {
        if (response.status) {
          if (this.companiesService.companies.length > 0) {
            this.authorized = true;
            this.companies = this.companiesService.companies;

            if (this.companies.length > 0) {
              if (this.companies.length == 1) {
                this.onCompanySelected(this.companies[0]);
              }
              else {
                this.showHideCompaniesList(true);
              }
            }
            else {
              this.logout();
            }
          }
        }
      },
      (err) => {
        if (err.headers.status == 403) {
          this.authorized = false;
        }
      }
    );
  }

  login(data: IDetailsFormDataUpdate) {
    this.showHideLogin(false);

    this.authenticationService.login(data.items.email, data.items.password)
      .pipe(first())
      .subscribe(
        data => {
          this.loadCompanies(1);
        });
  }

  logout() {
    this.showLogout(false);
    this.onCompanySelected(null);

    this.authenticationService.logout().pipe(first())
      .subscribe(
        data => {
          // this.dataService.userLoggedInfo = null;
          // this.hideMobileMenu();
          this.router.navigate(['/home']);
        });
  }

  register(data: IDetailsFormDataUpdate) {
    let body = <IRequestRegister>data.items;
    body.newsletter = false;
    this.authenticationService.register(body)
      .pipe(first())
      .subscribe(
        data => {
          this.showHideRegistration(false);
          this.showHideRegistrationOK(true);
        },
        err => {
          console.log('register Error', err.error);

          // if (err.error.error.errors.email) {
          //   this.registrationEmailAlreadyUsed = true;
          // } else {
          //   this.registrationGenericError = true;
          // }
        });
  }

  resendRegisterEmail() {
    this.showHideRegistrationOK(false);
    this.authenticationService.verificationNotification()
      .pipe(first())
      .subscribe(
        data => {
          // localStorage.setItem('email', this.authenticationService.currentUserValue.user.email);
          this.showHideRegistrationOK(true);
        },
        err => {
          console.log('forgotten password Error', err.error);
        });
  }

  onLostPasswordClick(data: IDetailsFormDataUpdate) {
    this.forgottenPwdEmail = data.items.email;

    this.showHideForgottenPassword(false);
    this.resendForgottenPassword();
  }

  resendForgottenPassword() {
    this.showHideForgottenPasswordOK(false);
    this.authenticationService.forgotPassword(this.forgottenPwdEmail)
      .pipe(first())
      .subscribe(
        data => {
          this.showHideForgottenPasswordOK(true);
        },
        err => {
          console.log('register Error', err.error);

          // if (err.error.error.errors.email) {
          //   this.forgottenEmailUnknow = true;
          // } else {
          //   this.forgottenEmailGenericError = true;
          // }
        });
  }

  onLostPassword() {
    this.showHideLogin(false);
    this.showHideForgottenPassword(true);
  }

  onRegisterUser() {
    this.showHideLogin(false);
    this.showHideRegistration(true);
  }

  onRegistrationOKClose() {
    this.logout();
  }

  onLostPasswordOKClose() {
    //
  }

  //Modals
  configuration: ITableConfiguration = {editEnabled: false, deleteEnabled: false, downloadEnabled: false, thumbnailKey: '',
                                        cols: [{key: 'name', label: this.trans.instant('Company'), type: eTableItemType.text}]};
  companies: ICompany[] = [];
  totalCompanies: number;
  companiesPerPage: number = 5;

  showHideLogin(show: boolean) {
    toggleModal('login-modal', show, 'email');
  }

  showHideRegistration(show: boolean) {
    if (show) this.modalRegisterComponent.resetData();
    toggleModal('register-modal', show, 'first_name');
  }

  showHideRegistrationOK(show: boolean) {
    toggleModal('registration-ok-modal', show);
  }

  showHideForgottenPassword(show: boolean) {
    toggleModal('forgotten-password-modal', show, 'email');
  }

  showHideForgottenPasswordOK(show: boolean) {
    toggleModal('forgotten-password-ok-modal', show);
  }

  showLogout(show: boolean) {
    if (show) {
      this.profileMenuIsOpen = false;
    }
    toggleModal('logout-popup', show);
  }

  showHideCompaniesList(show: boolean) {
    toggleModal('select-companies-modal', show);
  }

  onCompaniesListPageChange(page: number) {
    this.loadCompanies(page);
  }

  onCompanySelected(company: ICompany) {
    this.companyInfo = company;
    this.companiesService.setCompanyInfos(company);
    this.showHideCompaniesList(false);
  }
}
