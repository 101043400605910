<nav class="nav-container">
  <div class="container mx-auto">
    <div class="relative flex items-center justify-between h-16">
      <div class="absolute inset-y-0 left-0 flex items-center md:hidden">
        <!-- Mobile menu button-->
        <button *ngIf="isLoggedIn && authorized" type="button" class="hamburger" aria-controls="mobile-menu" aria-expanded="false" (click)="mobileMenuIsOpen = !mobileMenuIsOpen">
          <span class="sr-only">Open main menu</span>
          <svg class="h-6 w-6" [ngClass]="{'hidden': mobileMenuIsOpen, 'block': !mobileMenuIsOpen}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg class="h-6 w-6" [ngClass]="{'hidden': !mobileMenuIsOpen, 'block': mobileMenuIsOpen}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex-1 flex items-center justify-center md:items-stretch md:justify-start">
        <div class="flex-shrink-0 flex items-center">
          <img class="h-8 w-auto" src="/assets/images/logo.svg" alt="Workflow">
          <span class="title-logo">CRM</span>
        </div>
        <div class="hidden md:block md:ml-6">
          <div *ngIf="isLoggedIn && authorized" class="flex space-x-2">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <a class="desktop" [routerLink]="['/home']" [routerLinkActive]="'active'" aria-current="page">Home</a>
            <a class="desktop" [routerLink]="['/configure']" [routerLinkActive]="'active'">Configura</a>
            <a class="desktop" [routerLink]="['/customer']" [routerLinkActive]="'active'">Clienti</a>
            <a class="desktop" [routerLink]="['/orders']" [routerLinkActive]="'active'">Commesse</a>
<!--            <a class="desktop" [routerLink]="['/employees']" [routerLinkActive]="'active'">Dipendenti</a>-->
            <a class="desktop" [routerLink]="['/modules']" [routerLinkActive]="'active'">{{'Modules' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
<!--        <button type="button" class="bg-gray-700 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">-->
<!--          <span class="sr-only">View notifications</span>-->
<!--          &lt;!&ndash; Heroicon name: outline/bell &ndash;&gt;-->
<!--          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
<!--            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />-->
<!--          </svg>-->
<!--        </button>-->

        <!-- Profile dropdown -->
        <div class="ml-3 relative">
          <div>
            <button *ngIf="!isLoggedIn" type="button" class="btn-menu-profile" (click)="showHideLogin(true)" aria-expanded="false" aria-haspopup="true">
              <span class="sr-only">Login</span>
              <img class="h-10 w-10 p-1 rounded-full" alt="" src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBoZWlnaHQ9IjQ4cHgiIHZpZXdCb3g9IjAgMCAyMCAyMCIgd2lkdGg9IjQ4cHgiIGZpbGw9IndoaXRlIj48Zz48cmVjdCBmaWxsPSJub25lIiBoZWlnaHQ9IjIwIiB3aWR0aD0iMjAiIHg9IjAiLz48L2c+PGc+PHBhdGggZD0iTTEwLDR2MWg2djEwaC02djFoNmMwLjU1LDAsMS0wLjQ1LDEtMVY1YzAtMC41NS0wLjQ1LTEtMS0xSDEweiIvPjxwb2x5Z29uIHBvaW50cz0iOS41LDYuNSA4Ljc5LDcuMjEgMTEuMDksOS41IDMsOS41IDMsMTAuNSAxMS4wOSwxMC41IDguNzksMTIuNzkgOS41LDEzLjUgMTMsMTAiLz48L2c+PC9zdmc+'/>
<!--              <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">-->
            </button>
            <button *ngIf="isLoggedIn" type="button" class="btn-menu-profile" (click)="profileMenuIsOpen = !profileMenuIsOpen" aria-expanded="false" aria-haspopup="true">
              <span class="sr-only">Open user menu</span>
              <img class="h-10 w-10 p-1 rounded-full" alt="" src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDhweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iNDhweCIgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTAgMGgyNHYyNEgwVjB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEyIDUuOWMxLjE2IDAgMi4xLjk0IDIuMSAyLjFzLS45NCAyLjEtMi4xIDIuMVM5LjkgOS4xNiA5LjkgOHMuOTQtMi4xIDIuMS0yLjFtMCA5YzIuOTcgMCA2LjEgMS40NiA2LjEgMi4xdjEuMUg1LjlWMTdjMC0uNjQgMy4xMy0yLjEgNi4xLTIuMU0xMiA0QzkuNzkgNCA4IDUuNzkgOCA4czEuNzkgNCA0IDQgNC0xLjc5IDQtNC0xLjc5LTQtNC00em0wIDljLTIuNjcgMC04IDEuMzQtOCA0djNoMTZ2LTNjMC0yLjY2LTUuMzMtNC04LTR6Ii8+PC9zdmc+'/>
            </button>
          </div>

          <!-- Dropdown menu -->
          <div class="div-menu-profile" [ngClass]="{'hidden': !profileMenuIsOpen, 'block': profileMenuIsOpen}"
               role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1"
               (mouseleave)="profileMenuIsOpen = false">
            <!-- Active: "bg-gray-100", Not Active: "" -->
            <a *ngIf="user?.role == 'User'" role="button" class="menu-item" tabindex="-1" id="user-menu-item-0"
               [routerLink]="['/user-profile']" [routerLinkActive]="'active'">{{'Your Profile' | translate}}</a>
            <a role="button" class="menu-item" tabindex="-1" id="user-menu-item-2" (click)="showLogout(true)">{{'Sign out' | translate}}</a>
            <hr>
            <div class="menu-info">
              <span class="menu-item-user">{{userInfo}}</span>
              <span class="menu-item-user-company">{{companyInfo?.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div *ngIf="isLoggedIn && authorized" class="md:hidden" [ngClass]="{'hidden': !mobileMenuIsOpen, 'block': mobileMenuIsOpen}" id="mobile-menu">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
      <a class="mobile" [routerLink]="['/home']" [routerLinkActive]="'active'" aria-current="page">Home</a>
      <a class="mobile" [routerLink]="['/configure/company-data']" [routerLinkActive]="'active'">Configura</a>
      <a class="mobile" [routerLink]="['/customer']" [routerLinkActive]="'active'">Clienti</a>
      <a class="mobile" [routerLink]="['/orders']" [routerLinkActive]="'active'">Commesse</a>
      <a class="mobile" [routerLink]="['/modules']" [routerLinkActive]="'active'">{{'Modules' | translate}}</a>
    </div>
  </div>
</nav>

<div class="pt-16">
  <router-outlet></router-outlet>
</div>


<!--<modal-login [modalId]="'login-modal'" [title]="'Login al Sistema'" (dataUpdate)="login($event)" [configurations]="loginConfiguration"></modal-login>-->

<modal-login [modalId]="'login-modal'" [title]="'System Login' | translate" [showLostPassword]="false"
             [configurations]="loginConfiguration"
             [lostPasswordLabel]="'Lost Password?' | translate" [showRegisterUser]="false"
             [registerUserLabel]="'Not yet registered?' | translate" [registerUser2Label]="'Create your account' | translate"
             (onRegisterUser)="onRegisterUser()" (onLostPassword)="onLostPassword()"
             (dataUpdate)="login($event)"></modal-login>

<modal-register [modalId]="'register-modal'" [title]="'User Registration' | translate"
                (dataUpdate)="register($event)" [configurations]="registerConfigurations"></modal-register>

<modal [modalId]="'registration-ok-modal'" [showSeparators]="false" [title]="'Registration Complete!' | translate"
       [normalSize]="false" [widthFull]="false" [titleCss]="'modal-title'" (onClose)="onRegistrationOKClose()">
  <div body class="modal-body">
    <span class="block">{{'We have sent you a link to verify your email address and continue with the registration.' | translate}}</span>
    <br>
    <span>{{'Didn\'t receive emails? If you can\'t find the email, check the Spam folder.' | translate}}</span>
  </div>
  <div footer class="modal-footer">
    <custom-button class="ml-3 button-size" cssType="btn-outline-primary" [width]="eCustomButtonWidth.full"
                   [text]="'Send Again' | translate" (click)="resendRegisterEmail()"></custom-button>
  </div>
</modal>

<modal-forgotten-password [modalId]="'forgotten-password-modal'" [title]="'Reset password' | translate"
                          [message]="'Enter the email with which you registered. A message will be sent to you containing the link to update your password!' | translate"
                          [btnText]="'Send Request' | translate"
                          (dataUpdate)="onLostPasswordClick($event)" [configurations]="forgottenPasswordConfigurations">
</modal-forgotten-password>

<modal [modalId]="'forgotten-password-ok-modal'" [showSeparators]="false" [title]="'Reset Password received!' | translate"
       [normalSize]="false" [widthFull]="false" [titleCss]="'modal-title'" (onClose)="onLostPasswordOKClose()">
  <div body class="modal-body">
    <span class="block">{{'Your request has been successfully sent to the address' | translate}} </span>
    <span>{{forgottenPwdEmail}}. </span>
    <span>{{'Open the link we sent you and update your password!' | translate}}</span>
    <br>
    <span>{{'Didn\'t receive emails? If you can\'t find the email, check the Spam folder.' | translate}}</span>
  </div>
  <div footer class="modal-footer">
    <custom-button class="ml-3 button-size" cssType="btn-outline-primary" [width]="eCustomButtonWidth.full"
                   [text]="'Send Again' | translate" (click)="resendForgottenPassword()"></custom-button>
  </div>
</modal>

<modal [modalId]="'logout-popup'" [isPopup]="true" [popupIcon]="3" [popupMessage]="'Disconnettere l\'utente corrente?'">
  <div popup-footer>
    <custom-button cssType="btn-primary" text="OK" css="w-[100px]" (onClick)="logout()"></custom-button>
    <custom-button cssType="btn-outline-black w-[100px]" css="ml-3 w-[100px]" text="Annulla" (onClick)="showLogout(false)"></custom-button>
  </div>
</modal>

<modal [modalId]="'select-companies-modal'" [showSeparators]="false" [showFooterButtons]="false" [normalSize]="false" [widthFull]="false">
  <div body class="body-details">
    <custom-table [title]="'Please Select a Company' | translate" [showCollectionSize]="false" [smallRow]="true"
                  [configuration]="configuration" [items]="companies" [showTotalItems]="false"
                  [totalItems]="totalCompanies" [itemsPerPage]="companiesPerPage"
                  (onPageChange)="onCompaniesListPageChange($event)" (onItemSelected)="onCompanySelected($event)" ></custom-table>
  </div>
</modal>
