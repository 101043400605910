import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CompaniesService} from '../services/companies.service';
import {IDescription} from '../models/data.model';

@Injectable()
export class CompanyResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompany();
  }
}

@Injectable()
export class CompanyAddressesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyAddresses();
  }
}

@Injectable()
export class CompanyContactsResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyContacts();
  }
}

@Injectable()
export class CompanyCategoriesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyCategories();
  }
}

@Injectable()
export class CompanyAllCustomerStatusesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyCustomerStatuses(1, 1000);
  }
}


@Injectable()
export class CompanyAllCategoriesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (this.companiesService.allCategories.length == 0) {
      return this.companiesService.getCompanyCategories(1, 1000, true);
    }
    else {
      return this.companiesService.allCategories;
    }
  }
}

@Injectable()
export class CompanyQualificationsResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyQualifications();
  }
}

@Injectable()
export class CompanyUsersResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyUsers();
  }
}

@Injectable()
export class CompanyCustomerStatusesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyCustomerStatuses();
  }
}

@Injectable()
export class CompanyActivityTypesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyActivityTypes();
  }
}

@Injectable()
export class CompanyExpiryTypesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyExpiryTypes();
  }
}

@Injectable()
export class CompanyOrderTypesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyOrderTypes();
  }
}

@Injectable()
export class CompanyOrderAssignmentsTypeResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyOrderAssignmentsType();
  }
}

@Injectable()
export class CompanyOrderDetailsTypeResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyOrderDetailsType();
  }
}

@Injectable()
export class CompanyCoursesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyCourses();
  }
}

@Injectable()
export class CompanyCourseTrainingTypesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (route.data['all']) {
      return this.companiesService.getCompanyCourseTrainingTypes(1, 10000);
    }
    else {
      return this.companiesService.getCompanyCourseTrainingTypes();
    }
  }
}

@Injectable()
export class CompanyCourseTypesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyCourseTypes();
  }
}

@Injectable()
export class CompanyCourseModesResolver implements Resolve<any> {
  constructor(private companiesService: CompaniesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.companiesService.getCompanyCourseModes();
  }
}

// @Injectable()
// export class CompanyCourseLocationsResolver implements Resolve<any> {
//   constructor(private companiesService: CompaniesService) {}
//
//   resolve(route: ActivatedRouteSnapshot) {
//     return this.companiesService.getCompanyCourseLocations();
//   }
// }
