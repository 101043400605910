import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ITableColConfigurations } from '../classes/interfaces';
import { eTableItemType } from '../classes/enums';
import { dateUtils } from '../classes/utility';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'getTableValue' })
export class GetTableValuePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {
  }

  transform(item: any, conf: ITableColConfigurations, currencySymbol: string = '€'): SafeHtml {
    let key = conf.key.replace(/->/g, '.');
    let value = null;
    if (item) {
      try {
        value = key.split('.').reduce(function(p,prop) { return p[prop] }, item);
      } catch (error) {}
    }

    if (value) {
      switch (conf.type) {
        case eTableItemType.date:
          if (!conf.dateOptions?.showOnlyDate && !conf.dateOptions?.showOnlyTime) {
            let v = dateUtils.getDate(value, conf.dateOptions?.separator != null ? conf.dateOptions?.separator : '-',
                                      conf.dateOptions?.startFormat != null ? conf.dateOptions?.startFormat : 'dd/MM/yyyy');
            value = v + ' ' + dateUtils.getTime(value, !conf.dateOptions?.hideSeconds);
          }
          else if (conf.dateOptions?.showOnlyDate) {
            value = dateUtils.getDate(value, conf.dateOptions?.separator != null ? conf.dateOptions?.separator : '-',
                                      conf.dateOptions?.startFormat != null ? conf.dateOptions?.startFormat : 'dd/MM/yyyy');
          }
          else if (conf.dateOptions?.showOnlyTime) {
            value = dateUtils.getTime(value, !conf.dateOptions?.hideSeconds);
          }
          break;
        case eTableItemType.currency:
          value = currencySymbol + ' ' + this.decimalPipe.transform(value, '1.2-2');
          break;
        default:
          break;
      }
    }

    return value;
  }
}
