<div class="container mx-auto">
  <div *ngIf="isLoggedIn && companyInfo" class="">
    <div class="company-title">
      <span>{{companyInfo?.name}}</span>
    </div>
  </div>

  <div *ngIf="!isLoggedIn" class="not-logged-in-container">
    <img class="h-60 -mt-24" alt="" src="assets/images/logo.svg">
    <div class="absolute inset-x-0 bottom-20 text-center w-full">
      <span *ngIf="version != ''" class="text-xs italic">{{'Vrs. ' + version}}</span>
    </div>
  </div>

  <div *ngIf="isLoggedIn && companyInfo" class="pt-3 mb-10">
    <div class="border rounded p-3 pb-0">
      <div class="main-container">
        <div class="w-full">
          <detail-form #filter [title]="'Search Filter' | translate" [small]="env.smallItems"
          [configurations]="filterConfig" [item]="filterData" [isNew]="false" [isReadOnly]="false"
          [hideUpDownNumberAlways]="true" [showFooter]="false" [gridColMax]="true"
          (dataUpdate)="filterUpdate($event)" (selectChanged)="$event">
        </detail-form>
        </div>
        <div class="lg:ml-3">
          <button class="btn btn-primary" (click)="submit()">{{'Search' | translate}}</button>
        </div>
      </div>

      <div class="my-3"[ngClass]="{'mb-16': dashboard == null || dashboard?.deadlines?.length == 0}">
        <custom-table [title]="'Orders Invoices'" [showCollectionSize]="false" [smallRow]="env.smallItems" [showTotalItems]="false"
                      [configuration]="deadlinesConfiguration" [items]="dashboard?.deadlines"
                      [page]="deadlinesPage" [paginationID]="'pag-deadlines'"
                      [totalItems]="deadlinesTotalItems" [itemsPerPage]="deadlinesItemsPerPage"
                      [showTotalItems]="env.showTotalItems" [showItemsPerPage]="env.showItemsPerPage"
                      (onItemPerPageChange)="deadlinesOnItemPerPageChange($event)"
                      (onItemSelected)="deadlinesOnItemSelected($event)">
        </custom-table>
      </div>
      <!-- <hr> -->
      <div class="my-3" [ngClass]="{'mb-16': dashboard == null || dashboard?.planners?.length == 0}">
        <custom-table [title]="'Planning'" [showCollectionSize]="false" [smallRow]="env.smallItems" [showTotalItems]="false"
                      [configuration]="plannersConfiguration" [items]="dashboard?.planners"
                      [page]="plannersPage" [paginationID]="'pag-planners'"
                      [totalItems]="plannersTotalItems" [itemsPerPage]="plannersItemsPerPage"
                      [showTotalItems]="env.showTotalItems" [showItemsPerPage]="env.showItemsPerPage"
                      (onItemPerPageChange)="plannersOnItemPerPageChange($event)"
                      (onItemSelected)="plannersOnItemSelected($event)">
        </custom-table>
      </div>
      <div class="mt-3" [ngClass]="{'mb-16': dashboard == null || dashboard?.projects?.length == 0}">
        <custom-table [title]="'Orders'" [showCollectionSize]="false" [smallRow]="env.smallItems" [showTotalItems]="false"
                      [configuration]="projectsConfiguration" [items]="dashboard?.projects"
                      [page]="projectsPage" [paginationID]="'pag-projects'"
                      [totalItems]="projectsTotalItems" [itemsPerPage]="projectsItemsPerPage"
                      [showTotalItems]="env.showTotalItems" [showItemsPerPage]="env.showItemsPerPage"
                      (onItemPerPageChange)="projectsOnItemPerPageChange($event)"
                      (onItemSelected)="projectsOnItemSelected($event)">
        </custom-table>
      </div>
      <div class="mt-3" [ngClass]="{'mb-16': dashboard == null || dashboard?.tasks?.length == 0}">
        <custom-table [title]="'Tasks'" [showCollectionSize]="false" [smallRow]="env.smallItems" [showTotalItems]="false"
                      [configuration]="tasksConfiguration" [items]="dashboard?.tasks"
                      [page]="taskPage" [paginationID]="'pag-tasks'"
                      [totalItems]="tasksTotalItems" [itemsPerPage]="tasksItemsPerPage"
                      [showTotalItems]="env.showTotalItems" [showItemsPerPage]="env.showItemsPerPage"
                      (onItemPerPageChange)="tasksOnItemPerPageChange($event)"
                      (onItemSelected)="tasksOnItemSelected($event)">
        </custom-table>
      </div>
    </div>
  </div>
</div>
