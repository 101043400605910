import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from "ngx-ui-loader";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      request = request.clone({headers: request.headers.set('Authorization', 'Bearer ' + currentUser.token)});
    }

    if (request.method == "GET" || request.method == "PUT") {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
      }

      if (!request.headers.has('Accept')) {
        request = request.clone({headers: request.headers.set('Accept', 'application/json')});
      }
    }

    return next.handle(request).pipe(
      catchError(err => {
        console.log('ERROR!', err);
        let message = 'Errore sconosciuto!';
        if (err.error.message) {
          message = err.error.message;
        } else if (err.error.error.message) {
          message = err.error.error.message;
        } else {
          message = err.error.error;
        }

        this.authenticationService.showError(message);

        let logout = false;
        if (err.status === 401) {
          logout = true;
        }

        if (logout && request.url.indexOf('home') == -1) {
          this.authenticationService.logout();
          localStorage.removeItem('currentUser');
          localStorage.removeItem('email');
          localStorage.removeItem('current-company');
          this.router.navigate(['/home']);
        }

        return throwError(err);
      }));
  }
}

@Injectable()
export class NGXLoaderInterceptorService implements HttpInterceptor {
  constructor(private ngxLoader: NgxUiLoaderService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('/assets/')) {
      this.ngxLoader.start();
    }

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && !event.url.includes('/assets/')) {
          this.ngxLoader.stop();
        }
      },
      (err: any) => {
        this.ngxLoader.stop();
      }));
  }
}
