import {
  AfterContentChecked,
  AfterContentInit, AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {IContextMenu, IPos} from "../classes/interfaces";

@Component({
  selector: 'context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit, OnChanges, AfterContentChecked, AfterViewInit {
  @Input() configuration: IContextMenu[] = []
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() x = 0;
  @Input() y = 0;
  @Input() objectOffsetX = 0;
  @Input() objectOffsetY = 0;

  @Output() mouseOut = new EventEmitter<MouseEvent>();
  @Output() onItemClick = new EventEmitter<IContextMenu>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.configuration && this.configuration.length > 0) {
      this.setupMenu();
    }
  }

  ngAfterContentChecked() {
    // let menu = document.getElementById('main-context-menu');
    // if (menu) {
    //   let container = menu.parentElement.parentElement;
    //
    //   this.openFromRight = this.x + menu.offsetWidth > container.offsetWidth;
    //   if (this.openFromRight) {
    //     this.x -= menu.offsetWidth;
    //   }
    //
    //   this.openFromBottom = this.y + menu.offsetHeight > container.offsetHeight;
    //   if (this.openFromBottom) {
    //     this.y -= menu.offsetHeight;
    //   }
    //
    //   console.log('MAIN this.openFromRight, this.openFromBottom', this.openFromRight, this.openFromBottom)
    // }
    let menu = document.getElementById('main-context-menu');
    if (menu) {
      let container = menu.parentElement.parentElement;
      if (this.x + menu.offsetWidth > container.offsetWidth) {
        this.x -= menu.offsetWidth;
      }
      if (this.y + menu.offsetHeight > container.offsetHeight) {
        this.y -= menu.offsetHeight;
      }
    }
  }

  ngAfterViewInit() {
    // let menu = document.getElementById('main-context-menu');
    // if (menu) {
    //   let container = menu.parentElement.parentElement;
    //   if (this.x + menu.offsetWidth > container.offsetWidth) {
    //     this.x -= menu.offsetWidth;
    //   }
    //   if (this.y + menu.offsetHeight > container.offsetHeight) {
    //     this.y -= menu.offsetHeight;
    //   }
    // }
  }

  onMouseEnter(event: MouseEvent) {
    //
  }

  onMouseLeave(event: MouseEvent) {
    this.mouseOut.emit(event);
  }

  onSubItemClicked(item: IContextMenu) {
    this.onItemClick.emit(item);
  }

  onDivContextMenu(event: MouseEvent) {
    event.stopPropagation();

    return false;
  }

  private setupMenu() {

  }
}
