import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {EventService} from "./events.service";
import {BehaviorSubject, Observable} from "rxjs";
import {ePopupIcon} from "../../custom-component-lib/classes/enums";
import {toggleModal} from "../../custom-component-lib/classes/shared-functions";

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private generalPopup: BehaviorSubject<IGeneralPopup>;
  private generalQuestionAnswer: BehaviorSubject<boolean>;

  constructor(private translate: TranslateService,
              private eventService: EventService) {
    this.generalPopup = new BehaviorSubject<IGeneralPopup>({title: '', description: '', type: ePopupIcon.error});
    this.generalQuestionAnswer = new BehaviorSubject<boolean>(false);

    this.setLanguageFromBrowserSettings();
  }

  setLanguageFromBrowserSettings() {
    this.translate.setDefaultLang('en');

    let code = this.getLanguageFromBrowserSettings();

    this.useLanguage(code);
  }

  useLanguage(language: string) {
    this.translate.use(language).subscribe(() => {
      this.eventService.onTranslationsLoaded();
    });
  }

  getLanguageFromBrowserSettings() {
    //   console.log('setLanguageFromBrowserSettings');
    //   let t = navigator.language;
    //   console.log('LANGUAGE = ', t);
    //
    //   if (t.indexOf('it') != -1) {
    //     t = 'it';
    //   }
    //
    //   if (t == 'en' || t == 'de' || t == 'it') {
    //     //OK!
    //   } else {
    //     t = 'en';
    //   }
    //
    //   this.useLanguage(t);
    //   console.log('SET LANGUAGE = ', t);
    // }

    return 'it';
  }

  showGeneralPopup(value: IGeneralPopup) {
    setTimeout(()=> {
      this.generalPopup.next(value);
      toggleModal('general-error-popup', true);
    }, 50);
  }

  showQuestionPopup(value: IGeneralPopup) {
    setTimeout(()=> {
      this.generalPopup.next(value);
      toggleModal('general-question-popup', true);
    }, 50);
  }

  getGeneralPopup(): Observable<IGeneralPopup> {
    return this.generalPopup.asObservable();
  }
}

export interface IGeneralPopup {
  title: string;
  description: string;
  type: ePopupIcon;
  btnOKText?: string;
  btnCancelText?: string;
}
