import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularMyDatePickerDirective, CalAnimation, IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { IFormConfig } from 'src/custom-component-lib/classes/interfaces';

@Component({
  selector: 'my-date-selector',
  templateUrl: './my-date-selector.component.html',
  styleUrls: ['./my-date-selector.component.scss']
})
export class MyDateSelectorComponent  implements OnInit {
  @Input() config: IFormConfig = null;
  @Input() showLabel: boolean = false;
  @Input() formId: string = '';
  @Input() value: any;
  @Input() toggleOnClick: boolean = false;
  @Input() options: IAngularMyDpOptions = {
    calendarAnimation: { in: CalAnimation.Fade, out: CalAnimation.None },
    dateRange: false,
    dateFormat: 'dd/mm/yyyy'
  }
  @Input() locale: string;

  @Output() onChange = new EventEmitter<IMyDateModel>();
  @Output() onClick = new EventEmitter<AngularMyDatePickerDirective>();

  @ViewChild('dateCalendar') dateCalendarComponent: AngularMyDatePickerDirective;

  constructor() {

  }

  ngOnInit() {

  }

  click() {
    if (this.toggleOnClick) this.toggle();

    this.onClick.emit(this.dateCalendarComponent);
  }

  open() {
    this.dateCalendarComponent.openCalendar();
  }

  close() {
    this.dateCalendarComponent.closeCalendar();
  }

  toggle() {
    this.dateCalendarComponent.toggleCalendar();
  }

  isDateValid() {
    return this.dateCalendarComponent.isDateValid();
  }
}
