import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {HomeComponent} from './components/dashboard/home/home.component';
import {AuthGuard} from './guards/auth.guard';
import {DashboardResolver, VersionResolver} from './resolvers/resolvers.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    component: DashboardComponent,
    resolve: {version: VersionResolver},
    children: [
      {
        path: 'home',
        component: HomeComponent,
        resolve: {dashboard: DashboardResolver}
      },
      {
        path: "configure",
        redirectTo: "configure/company-data"
      },
      {
        path: 'configure',
        canActivate: [AuthGuard],
        loadChildren: () => import('./components/dashboard/configure/configure.module').then(m => m.ConfigureModule)
      },
      {
        path: 'customer',
        canActivate: [AuthGuard],
        loadChildren: () => import('./components/dashboard/customers/customers.module').then(m => m.CustomersModule)
      },
      {
        path: 'orders',
        canActivate: [AuthGuard],
        loadChildren: () => import('./components/dashboard/orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'modules',
        canActivate: [AuthGuard],
        loadChildren: () => import('./components/dashboard/modules/modules.module').then(m => m.ModulesModule)
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
