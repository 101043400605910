<div class="menu-mobile-container">
  <div class="menu-mobile">
    <img role="button" tabindex="0" alt="" src="assets/images/hamburger-white.svg" [ngClass]="{'hidden': menuMobileIsShowed}" (click)="menuMobileIsShowed = !menuMobileIsShowed">
    <img role="button" tabindex="0" alt="" src="assets/images/close-white.svg"  [ngClass]="{'hidden': !menuMobileIsShowed}" (click)="menuMobileIsShowed = !menuMobileIsShowed">
    <div class="title">{{title}}</div>
  </div>
</div>
<div class="sidebar-container">
  <div class="grid grid-cols-12">
    <div class="sidebar" [ngClass]="{'show-mobile-menu': menuMobileIsShowed}">
      <div class="menu-block">
        <div class="title" translate="">{{'Base Modules' | translate}}</div>
        <menu-item [text]="'Reporting'" [imgSource]="'/assets/images/file-invoice.svg'" [link]="'/modules/internal-reporting'"></menu-item>
        <menu-item [text]="'Courses Management'" [imgSource]="'/assets/images/training.svg'" [link]="'/modules/trainings'"></menu-item>
        <menu-item [text]="'Planning'" [imgSource]="'/assets/images/planning.svg'" [link]="'/modules/planning'"></menu-item>
      </div>
    </div>
    <div class="content" [ngClass]="{'hidden': menuMobileIsShowed}">
      <div class="sm:block hidden">
        <div class="content-title mb-2">{{title}}</div>
        <hr>
      </div>

      <div class="mt-0 mb-5 sm:mt-4 mx-2 sm:mx-0">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
