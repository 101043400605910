import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/dashboard/home/home.component';
import { CustomersComponent } from './components/dashboard/customers/customers.component';
import { OrdersComponent } from './components/dashboard/orders/orders.component';
import { EmployeesComponent } from './components/dashboard/configure/To Remove/employees/employees.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TwCustomComponentsModule} from '../custom-component-lib/tw-custom-components.module';
import {JwtInterceptor, NGXLoaderInterceptorService} from './guards/jwt-interceptor';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {NgxUiLoaderModule} from "ngx-ui-loader";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DashboardResolver, RegionsResolver, VersionResolver} from './resolvers/resolvers.resolver';
import {
  CompanyAllCategoriesResolver,
  CompanyAllCustomerStatusesResolver,
  CompanyResolver
} from './resolvers/configure.resolver';
import { ModulesComponent } from './components/dashboard/modules/modules.component';

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        HomeComponent,
        CustomersComponent,
        OrdersComponent,
        EmployeesComponent,
        ModulesComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        NgxUiLoaderModule,
        NgxPaginationModule,
        FormsModule,
        ReactiveFormsModule,
        TwCustomComponentsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: NGXLoaderInterceptorService, multi: true},
        VersionResolver, RegionsResolver, CompanyAllCategoriesResolver,
        CompanyAllCustomerStatusesResolver, CompanyResolver, DashboardResolver],
    exports: [],
    bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
