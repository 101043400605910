<div *ngIf="title != ''" class="font-semi-bold text-xl md:text-lg mb-3" [ngClass]="{'font-bold text-sm md:text-base mb-0': small}">
  <span>{{title | translate}}</span>
</div>

<div *ngIf="configurations.length > 0">
    <div class="grid grid-cols-3 sm:grid-cols-6 gap-x-4 gap-y-2" [ngClass]="{'gap-x-3 gap-y-1': small}">
      <div *ngFor="let conf of configurations; let i = index" class="col-span-3"
           [ngClass]="{'sm:col-span-6': conf.fullRow, 'flex items-end mt-1': conf.type == eDetailType.boolean,
                       'lg:col-span-1': conf.colLength == 1, 'lg:col-span-2': conf.colLength == 2,
                       'lg:col-span-3': conf.colLength == 3, 'lg:col-span-4': conf.colLength == 4,
                       'lg:col-span-5': conf.colLength == 5}">
        <div *ngIf="conf.type == eDetailType.boolean">
          <label class="inline-flex items-center">
            <input id="detail-form-chk-{{conf.key}}'" name="detail-form-chk-{{conf.key}}" type="checkbox" class="custom-checkbox" [checked]="setData(conf)" disabled>
            <span class="ml-1 text-[14px]">{{conf.label | translate}}</span>
          </label>
        </div>
        <div *ngIf="conf.type == eDetailType.button" class="w-full h-full flex items-end">
          <button class="btn button-link" (click)="buttonLinkClick(setData(conf))">{{conf.label}}</button>
        </div>
        <div *ngIf="conf.type != eDetailType.boolean && conf.type != eDetailType.button">
          <label [ngClass]="{'small': small}">{{conf.label | translate}}</label>
          <div class="{{conf.class}} bg-gray-50 mt-1">
            <div *ngIf="!conf.applyValueAsHTML" class="data-container" [ngClass]="{'small': small}"
                 [ngClass]="{'text-center': conf.type == eDetailType.number || conf.type == eDetailType.currency || conf.type == eDetailType.date,
                             'small': small}">
              {{conf.type == eDetailType.text ? (setData(conf) | translate) : setData(conf)}}
            </div>
            <div *ngIf="conf.applyValueAsHTML" [innerHTML]="item[conf.key] | noSanitize"
                 class="data-container overflow-auto sm:overflow-y-hidden"></div>
          </div>
        </div>
      </div>
   </div>
</div>
