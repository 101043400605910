import {IUser} from "./data.model";

export interface Deserializable {
  deserialize(input: any): this;
}

export class IResponse implements Deserializable {
  status: boolean;
  time: string;
  data: any;
  error: any;
  meta?: any;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

export class IPagination {
  total: number;
  count: number;
  per_page: number;
  current_page: number;
  total_pages: number;
  links: {
    next?: string;
    previous?: string;
  }
}

export class Login implements Deserializable {
  token: string;
  type: string;
  user: IUser;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
