import { Component } from '@angular/core';
import {eCustomButtonWidth, ePopupIcon } from 'src/custom-component-lib/classes/enums';
import {AppService, IGeneralPopup} from "./services/app.service";
import {AuthenticationService} from "./services/authentication.service";
import {EventService} from "./services/events.service";
import {toggleModal} from "../custom-component-lib/classes/shared-functions";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'crm';

  ePopupIcon = ePopupIcon;
  eCustomButtonWidth = eCustomButtonWidth;
  apiError: string = '';
  generalError: IGeneralPopup;

  constructor(private appService: AppService,
              private auth: AuthenticationService,
              private eventService: EventService) {
    auth.getApiError().subscribe((value) => {
      this.apiError = value;
    });

    appService.getGeneralPopup().subscribe((value: IGeneralPopup) => {
      this.generalError = value;
    })
  }

  hidePopupError() {
    toggleModal('api-error-popup', false);
  }

  hideGeneralError() {
    toggleModal('general-error-popup', false);
  }

  hideGeneralQuestion() {
    toggleModal('general-question-popup', false);
  }

  generalQuestionClick(ok: boolean) {
    this.eventService.onGeneralQuestionAnswer(ok);
    this.hideGeneralQuestion();
  }
}
