import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit {
  title: string = '';
  menuMobileIsShowed: boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private trans: TranslateService) {
    this.subscribeRoutes();
  }

  ngOnInit(): void {

  }

  subscribeRoutes() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (this.route.snapshot.firstChild.data.title) {
          this.title = this.trans.instant(this.route.snapshot.firstChild.data.title);
          this.menuMobileIsShowed = false;
        }
      }
    });
  }
}
