<div class="main-container">
  <div class="col-span-3">
    <label class="bold">{{filterTitle}}</label>
    <select class="custom-input small" [(ngModel)]="selectedName" (ngModelChange)="onSelectFilterChange($event)">

      <option *ngFor="let item of config; let i = index" [ngValue]="item.name">
        {{item.label}}
      </option>
    </select>
  </div>

  <div [ngSwitch]="selectedFilter?.type" class="filters-container">
    <label *ngIf="showDefaultLabel" class="bold">{{selectedFilter?.label}}</label>
    <div *ngSwitchCase="filterType.input">
      <my-input [formId]="objIdFixedPart + selectedName" [config]="selectedFilter" [showLabel]="false"
                [value]="selectedFilter?.current?.value" (onChange)="onInputOrSelectChange($event)"></my-input>
    </div>
    <div *ngSwitchCase="filterType.multiInput">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-2">
        <my-input [formId]="objIdFixedPart + selectedName + '_from'" [config]="selectedFilter" [showLabel]="true"
                [value]="selectedFilter?.current?.valueFrom" (onChange)="onInputOrSelectChange($event, true)"></my-input>
        <my-input [formId]="objIdFixedPart + selectedName + '_to'" [config]="selectedFilter" [showLabel]="true"
                [value]="selectedFilter?.current?.valueTo" (onChange)="onInputOrSelectChange($event)"></my-input>
      </div>
    </div>
    <div *ngSwitchCase="filterType.select">
      <my-select [formId]="objIdFixedPart + selectedName" [config]="selectedFilter" [showLabel]="false"
                [value]="selectedFilter?.current?.value" [selectConfig]="selectedFilter?.select[0]"
                (onChange)="onInputOrSelectChange($event)"></my-select>
    </div>
    <div *ngSwitchCase="filterType.multiSelect">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-2">
        <my-select [formId]="objIdFixedPart + selectedName + '_from'" [config]="selectedFilter" [showLabel]="true"
                [value]="selectedFilter?.current?.valueFrom" [selectConfig]="selectedFilter?.select[0]"
                (onChange)="onInputOrSelectChange($event, true)"></my-select>
        <my-select [formId]="objIdFixedPart + selectedName + '_to'" [config]="selectedFilter" [showLabel]="true"
                [value]="selectedFilter?.current?.valueTo" [selectConfig]="selectedFilter?.select[1]"
                (onChange)="onInputOrSelectChange($event)"></my-select>
      </div>
    </div>
    <div *ngSwitchCase="filterType.dateSingle">
      <my-date-selector [formId]="objIdFixedPart + selectedName" [config]="selectedFilter" [showLabel]="false"
                        [value]="selectedFilter?.current?.date" [toggleOnClick]="true" [locale]="dateLocale"
                        (onChange)="onDateChanged($event)" #dateCalendar></my-date-selector>
    </div>
    <div *ngSwitchCase="filterType.dateFromTo">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-2">
        <my-date-selector [formId]="objIdFixedPart + selectedName + '_from'" [config]="selectedFilter" [showLabel]="true"
                          [value]="selectedFilter?.current?.dateFrom" [toggleOnClick]="true" [locale]="dateLocale"
                          (onChange)="onDateChanged($event, true)" #dateCalendarFrom></my-date-selector>
        <my-date-selector [formId]="objIdFixedPart + selectedName + '_to'" [config]="selectedFilter" [showLabel]="true"
                          [value]="selectedFilter?.current?.dateTo" [toggleOnClick]="true" [locale]="dateLocale"
                          (onChange)="onDateChanged($event)" #dateCalendarTo></my-date-selector>
      </div>
    </div>
    <div *ngSwitchCase="filterType.monthYearSingle">
      <my-month-selector [formId]="objIdFixedPart + selectedName" [config]="selectedFilter" [showLabel]="false"
                         [value]="selectedFilter?.current?.date" [openOnClick]="true" [locale]="dateLocale"
                         (onChange)="onDateChanged($event)" #monthCalendar></my-month-selector>
    </div>
    <div *ngSwitchCase="filterType.monthYearFromTo">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-2">
        <my-month-selector [formId]="objIdFixedPart + selectedName + '_from'" [config]="selectedFilter" [showLabel]="true"
                         [value]="selectedFilter?.current?.dateFrom" [openOnClick]="true" [locale]="dateLocale"
                         (onChange)="onDateChanged($event, true)" #monthCalendarFrom></my-month-selector>
        <my-month-selector [formId]="objIdFixedPart + selectedName + '_to'" [config]="selectedFilter" [showLabel]="true"
                         [value]="selectedFilter?.current?.dateTo" [openOnClick]="true" [locale]="dateLocale"
                         (onChange)="onDateChanged($event)" #monthCalendarTo></my-month-selector>
      </div>
    </div>
    <div *ngSwitchCase="filterType.checkbox">
      <my-check-box [formId]="objIdFixedPart + selectedName" [config]="selectedFilter" [showLabel]="true"
                    [value]="selectedFilter?.current?.value" (onChange)="onInputOrSelectChange($event)"></my-check-box>
    </div>
    <div *ngSwitchCase="filterType.multiCheckbox">
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-x-2">
        <div *ngFor="let chkItem of selectedFilter?.checkBox?.list let i = index">
          <my-check-box [formId]="objIdFixedPart + selectedName + '_' + index" [config]="selectedFilter" [showLabel]="true"
                        [value]="selectedFilter?.current?.values[i]" [label]="chkItem" [small]="true"
                        (onChange)="onMultiCheckBoxChange(i, $event)"></my-check-box>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons-container" [ngClass]="{'center-button': selectedFilter?.type == filterType.multiCheckbox}">
    <div class="button-container ok" (click)="onApplyFilter()">
      <svg style="enable-background:new 0 0 36 36;" version="1.1" viewBox="0 0 36 36">
        <g id="Layer_1"/><g id="icons">
          <path fill="currentColor" d="M12.8,28.7l-9.5-9.5c-0.4-0.4-0.4-1.1,0-1.6l1.5-1.5c0.4-0.4,1.1-0.4,1.6,0l7.2,7.2   l16-16c0.4-0.4,1.1-0.4,1.6,0l1.5,1.5c0.4,0.4,0.4,1.1,0,1.6L14.4,28.7C13.9,29.1,13.2,29.1,12.8,28.7z" id="check_x5F_mark_1_"/>
        </g>
      </svg>
    </div>
    <div class="button-container cancel" (click)="onResetFilter()">
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z"/>
      </svg>
    </div>
  </div>
</div>

<div *ngIf="showSelectedFilters" class="mt-3">
  <div *ngIf="filters.length == 0 && showNoFiltersMessage" class="border rounded py-1">
    <div class="border rounded py-1 mt-2" *ngIf="filters.length == 0" class="w-full text-center text-sm">Nessun filtro impostato</div>
  </div>
  <div *ngIf="filters.length > 0" class="w-full flex flex-wrap gap-2">
    <div *ngFor="let item of filters; let i = index" class="filters-selected-container" [ngClass]="{'searched': item.current?.searched}">
      <span (click)="gotoFilter(item)">{{item.label}}<span *ngIf="showValuesOnSelectedFilters">:  {{item.current?.description}}</span></span>
      <div class="ml-2 cursor-pointer" (click)="onCloseFilterClicked(item)">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.3394 9.32245C16.7434 8.94589 16.7657 8.31312 16.3891 7.90911C16.0126 7.50509 15.3798 7.48283 14.9758 7.85938L12.0497 10.5866L9.32245 7.66048C8.94589 7.25647 8.31312 7.23421 7.90911 7.61076C7.50509 7.98731 7.48283 8.62008 7.85938 9.0241L10.5866 11.9502L7.66048 14.6775C7.25647 15.054 7.23421 15.6868 7.61076 16.0908C7.98731 16.4948 8.62008 16.5171 9.0241 16.1405L11.9502 13.4133L14.6775 16.3394C15.054 16.7434 15.6868 16.7657 16.0908 16.3891C16.4948 16.0126 16.5171 15.3798 16.1405 14.9758L13.4133 12.0497L16.3394 9.32245Z" fill="currentColor"/><path clip-rule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" fill="currentColor" fill-rule="evenodd"/>
        </svg>
      </div>
    </div>
  </div>
</div>
