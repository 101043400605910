import { Injectable } from '@angular/core';
import {ITableFilter, ITableSortSelected} from "../../custom-component-lib/classes/interfaces";
import {eTableSortStatus} from "../../custom-component-lib/classes/enums";
import {IAddress, ICompany, IContact, IProject} from "../models/data.model";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import {EventService} from "./events.service";
import {map} from "rxjs/operators";
import {IResponse} from "../models/response.model";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  itemsPerPage: number = environment.itemsPerPage;
  selectedCompany: ICompany;
  selectedCompanyUUID: string = '';
  selectedCustomer: ICompany;

  private baseUrl = `${environment.apiUrl}/companies`

  constructor(private httpClient: HttpClient,
              private authenticationService: AuthenticationService,
              private eventService: EventService,
              private router: Router) {
  }

  setCompanyInfos(company: ICompany) {
    this.selectedCompany = company;
    this.selectedCompanyUUID = company?.uuid;
  }

  setCustomerInfos(company: ICompany) {
    this.selectedCustomer = company;

    this.eventService.onCustomerSelectedChanged(this.selectedCustomer)
  }

  getParams(page: number = 1, perPage: number = this.itemsPerPage, sort: ITableSortSelected, filter: ITableFilter = null) {
    return this.authenticationService.getParams(page, perPage, sort, filter);
  }

  getCompanyCustomers(page: number = 1, perPage: number = this.itemsPerPage,
                      sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers', {params: params});
  }

  getCompanyCustomer(customer_id: string) {
   return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id).pipe(map(response => {
      let res = new IResponse().deserialize(response);

      if (res.status) {
        this.setCustomerInfos(<ICompany>res.data);
      }

      return res;
    }));
  }

  updateCompanyCustomer(data: ICompany, isNew: boolean) {
    // let body = {code: data.code, name: data.name, vat: data.vat, category: data.category, activity: data.activity, note: data.note};

    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + data.uuid, data).pipe(map(response => {
        let res = new IResponse().deserialize(response);

        if (res.status) {
          this.setCustomerInfos(<ICompany>res.data);
        }

        return res;
      }));
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers', data).pipe(map(response => {
        let res = new IResponse().deserialize(response);

        if (res.status) {
          this.setCustomerInfos(<ICompany>res.data);
          this.router.navigate(['customer/' + this.selectedCustomer.uuid]);
        }

        return res;
      }));
    }
  }

  deleteCompanyCustomer(customer_id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id);
  }

  //Customers Addresses
  getCompanyCustomerAddresses(customer_id: string, page: number = 1, perPage: number = this.itemsPerPage,
                              sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/addresses', {params: params});
  }

  getCompanyCustomerAddress(customer_id: string, address_id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/addresses/' + address_id);
  }

  updateCompanyCustomerAddress(customer_id: string, data: IAddress, isNew: boolean) {
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/addresses/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/addresses', data);
    }
  }

  deleteCompanyCustomerAddress(customer_id: string, address_id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/addresses/' + address_id);
  }

  //Customers Contact
  getCompanyCustomerContacts(customer_id: string, page: number = 1, perPage: number = this.itemsPerPage,
                             sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/contacts', {params: params});
  }

  getCompanyCustomerContact(customer_id: string, contact_id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/contacts/' + contact_id);
  }

  updateCompanyCustomerContact(customer_id: string, data: IContact, isNew: boolean) {
    // let body = {name: data.name, email: data.email, phone: data.phone, mobile: data.mobile, fax: data.fax, web: data.web};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/contacts/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/contacts', data);
    }
  }

  deleteCompanyCustomerContact(customer_id: string, contact_id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/contacts/' + contact_id);
  }

  //Customers Users
  getCompanyCustomerUsers(customer_id: string, page: number = 1, perPage: number = this.itemsPerPage,
                             sort: ITableSortSelected = {key: 'name', status: eTableSortStatus.asc}, filter: ITableFilter = null) {
    let params = this.getParams(page, perPage, sort, filter);

    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/users', {params: params});
  }

  getCompanyCustomerUser(customer_id: string, user_id: string) {
    return this.httpClient.get(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/users/' + user_id);
  }

  updateCompanyCustomerUser(customer_id: string, data: IContact, isNew: boolean) {
    // let body = {name: data.name, email: data.email, phone: data.phone, mobile: data.mobile, fax: data.fax, web: data.web};
    if (!isNew) {
      return this.httpClient.put(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/users/' + data.uuid, data);
    }
    else {
      return this.httpClient.post(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/users', data);
    }
  }

  deleteCompanyCustomerUser(customer_id: string, user_id: string) {
    return this.httpClient.delete(this.baseUrl + '/' + this.selectedCompanyUUID + '/customers/' + customer_id + '/users/' + user_id);
  }
}
