<div *ngIf="config != null" class="relative">
  <label *ngIf="showLabel" class="bold">{{config?.label}}</label>
  <input [id]="formId" [type]="config?.input?.type != null ? config?.input?.type : 'text'"
    class="custom-input {{config?.textAlign != null ? config?.textAlign : ''}} custom-input-no-spin small"
    placeholder="" [autocomplete]="config?.input?.autocomplete != null ? config?.input?.autocomplete : 'off'"
    [ngModel]="value" (ngModelChange)="changed($event)"
    (input)="value$.next($event.target.value)">
  <div *ngIf="config?.input?.autoCompleteFunctionEnabled && hasSearched">
    <div *ngIf="!isSearching && data?.length > 0" class="autocomplete-container data-found-container">
      <span class="title">Risultati ricerca</span>
      <div class="items-container">
        <a *ngFor="let item of data; let i = index"
          class="item" role="button" tabindex="-1" (click)="onItemClick(item)">
          <span>{{item}}</span>
        </a>
      </div>
    </div>
    <div *ngIf="!isSearching && data?.length == 0" class="autocomplete-container no-data">
      <div>Nessun dato trovato per i dati immessi</div>
    </div>
    <div *ngIf="isSearching" class="autocomplete-container ">
      <div class="flex justify-between">
        <div class="searching-text">Ricerca in corso...</div>
        <ngx-ui-loader [loaderId]="'autocomplete-loader'"
            [hasProgressBar]="false" [bgsType]="loaderType" [bgsColor]="'black'"
            [bgsPosition]="'center-center'" [bgsSize]="loaderSize">
        </ngx-ui-loader>
      </div>
    </div>
  </div>
</div>
