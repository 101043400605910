import {Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {fromEvent, Subscription} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {takeUntil} from "rxjs/operators";

@Directive({
  selector: '[myDragging]'
})
export class MyDraggingDirective implements OnInit, OnDestroy {
  @Input() canBeDragged: boolean = false;
  @Input() applyMove: boolean = false;
  @Input() zoomFactor: number = 1;

  @Output() onDragEnter = new EventEmitter<myDraggingInfo>();
  @Output() onDragMove = new EventEmitter<myDraggingInfo>();
  @Output() onDrop = new EventEmitter<myDraggingInfo>();

  private element: HTMLElement;
  private subscriptions: Subscription[] = [];

  private currentPosition: myPosition;
  private lastMovement: myPosition;
  private touchEventStart: myPosition = {x: 0, y: 0};
  private info: myDraggingInfo;

  constructor(private elementRef: ElementRef,
              @Inject(DOCUMENT) private document: any) {
  }

  ngOnInit(): void {
    this.element = this.elementRef.nativeElement as HTMLElement;

    this.initMouseDrag();
    this.initTouchDrag();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  initMouseDrag(): void {
    const dragStart$ = fromEvent<MouseEvent>(this.element, "mousedown");
    const dragEnd$ = fromEvent<MouseEvent>(this.document, "mouseup");
    const drag$ = fromEvent<MouseEvent>(this.document, "mousemove")
      .pipe(
        takeUntil(dragEnd$)
      );

    let dragSub: Subscription;
    const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
      if (this.canBeDragged) {
        this.onStartMove({x: event.clientX, y: event.clientY}, event);

        dragSub = drag$.subscribe((event: MouseEvent) => {
          event.preventDefault();

          this.onMove(event.movementX, event.movementY, {x: event.clientX, y: event.clientY}, event);
        });
      }
    });

    const dragEndSub = dragEnd$.subscribe((event: MouseEvent) => {
      event.preventDefault();
      this.onStopMove(dragSub);
    });

    this.subscriptions.push.apply(this.subscriptions, [
      dragStartSub, dragSub, dragEndSub,
    ]);
  }

  initTouchDrag(): void {
    const touchStart$ = fromEvent<TouchEvent>(this.element, "touchstart");
    const touchEnd$ = fromEvent<TouchEvent>(this.document, "touchend");
    const touchMove$ = fromEvent<TouchEvent>(this.document, "touchmove")
      .pipe(
        takeUntil(touchEnd$)
      );

    let touchSub: Subscription;
    const touchStartSub = touchStart$.subscribe((event: TouchEvent) => {
      if (this.canBeDragged) {
        let touch = event.touches[0];
        this.onStartMove({x: touch.clientX, y: touch.clientY});

        this.touchEventStart.x = touch.clientX;
        this.touchEventStart.y = touch.clientY;

        touchSub = touchMove$.subscribe((event: TouchEvent) => {
          // event.preventDefault();
          let touch = event.touches[0];
          let movementX = Math.round(touch.clientX - this.touchEventStart.x);
          let movementY = Math.round(touch.clientY - this.touchEventStart.y);

          this.touchEventStart.x = event.touches[0].clientX;
          this.touchEventStart.y = event.touches[0].clientY;

          this.onMove(movementX, movementY, {x: touch.clientX, y: touch.clientY});
        });
      }
    });

    const touchEndSub = touchEnd$.subscribe((event: TouchEvent) => {
      this.onStopMove(touchSub);
    });

    this.subscriptions.push.apply(this.subscriptions, [
      touchStartSub, touchSub, touchEndSub,
    ]);
  }

  private onStartMove(client: myPosition, mouseEvent: MouseEvent = null) {
    this.element.classList.add('on-drag');
    this.currentPosition = {x: this.element.offsetLeft, y: this.element.offsetTop};
    this.lastMovement = {x: 0, y:0};

    this.info = {startPosition: this.currentPosition, newPosition: this.currentPosition,
                 movement: this.lastMovement, positionHasChanged: false, client: client,
                 htmlElement: this.element, isTouch: mouseEvent == null, mouseEvent: mouseEvent};
    this.onDragEnter.emit(this.info);
  }

  private onMove(movementX: number, movementY: number, client: myPosition, mouseEvent: MouseEvent = null) {
    this.lastMovement = {x: (movementX / this.zoomFactor), y: (movementY / this.zoomFactor)};
    let newPosition = {x: this.currentPosition.x + this.lastMovement.x,
                       y: this.currentPosition.y + this.lastMovement.y};

    this.info = {startPosition: this.currentPosition, newPosition: newPosition,
      movement: this.lastMovement, positionHasChanged: true, client: client,
      htmlElement: this.element, isTouch:  mouseEvent == null, mouseEvent: mouseEvent};

    this.currentPosition = newPosition;

    if (this.applyMove) {
      // this.element.style.transform = "translate3d(" + currentX + "px, " + currentY + "px, 0)";
      this.element.style.left = (this.currentPosition.x) + 'px';
      this.element.style.top = (this.currentPosition.y) + 'px';
    }

    this.onDragMove.emit(this.info);
  }

  private onStopMove(dragSub: Subscription) {
    if (this.canBeDragged) {
      if (this.element.classList.contains('on-drag')) {
        this.element.classList.remove('on-drag');
        this.onDrop.emit(this.info);
      }

      if (dragSub) {
        dragSub.unsubscribe();
      }
    }
  }
}

export interface myPosition {
  x: number;
  y: number;
}

export interface myDraggingInfo{
  startPosition: myPosition;
  movement: myPosition;
  newPosition: myPosition;
  positionHasChanged: boolean;
  client: myPosition;
  htmlElement: HTMLElement;
  isTouch: boolean;
  mouseEvent?: MouseEvent;
}
