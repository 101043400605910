import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {eIcon} from "../classes/enums";
import {IPos, ISize} from "../classes/interfaces";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'svg-image',
  templateUrl: './svg-image.component.html',
  styleUrls: ['./svg-image.component.scss']
})
export class SvgImageComponent implements OnInit, OnChanges {
  @Input() containerSize = {width: 24, height: 24};
  @Input() type: eIcon = eIcon.none;
  @Input() position: IPos = {x: 0, y: 0};
  @Input() size: ISize = {width: 24, height: 24};
  @Input() fill: string = 'currentColor';
  @Input() fillDisabled: string = 'currentColor';
  @Input() fillOnHover: string = 'currentColor';
  @Input() ariaHidden: boolean = true;
  @Input() customBase64: string = '';
  @Input() customBase64Disabled: string = '';
  @Input() border: boolean = false;
  @Input() disabled: boolean = false;

  eIcon = eIcon;
  viewBox: string = '0 0 24 24';
  customImage = '';
  customImageDisabled = '';
  currentFillColor: string = this.fill;

  constructor(private sanitization: DomSanitizer) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.type != eIcon.custom && this.size != null) {
      this.viewBox = String(this.position.x) + ' ' + String(this.position.y) + ' ' +
        String(this.size.width) + ' ' + String(this.size.height);
    }

    this.customImage = <string>this.sanitization.bypassSecurityTrustUrl(this.customBase64);
    if (this.customBase64Disabled != undefined && this.customBase64Disabled != '') {
      this.customImageDisabled = <string>this.sanitization.bypassSecurityTrustUrl(this.customBase64Disabled);
    }
    else {
      this.customImageDisabled = this.customImage;
    }

    if (changes.disabled) {
      this.currentFillColor = this.disabled ? this.fillDisabled : this.fill;
    }
  }

  onMouseEnter(e: MouseEvent) {
    if (this.fill != this.fillOnHover) {
      this.currentFillColor = this.fillOnHover
    }
  }

  onMouseLeave(e: MouseEvent) {
    if (this.fill != this.fillOnHover) {
      this.currentFillColor = this.fill
    }

    this.currentFillColor = this.disabled ? this.fillDisabled : this.fill;
  }
}
