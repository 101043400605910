import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from "rxjs/operators";
import {environment} from '../../environments/environment';
import {IResponse} from '../models/response.model';
import {EventService} from './events.service';
import {IRegion} from '../models/data.model';
import {ITableFilter, ITableSortSelected} from "../../custom-component-lib/classes/interfaces";
import {AuthenticationService} from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  version: string = '';
  regions: IRegion[] = [];

  private baseUrl = `${environment.apiUrl}`

  constructor(private httpClient: HttpClient,
              private eventService: EventService,
              private authenticationService: AuthenticationService) { }

  testData() {
    // let body = JSON.parse('[{"Title":"Title 1","Description":"Book 1","Price":10},{"Title":"Title 2","Description":"Book 2","Price":20},{"Title":"Title 3","Description":"Book 3","Price":30}]');
    // return this.httpClient.post<any>('https://httpbin.org/post', body).pipe(map(response => {
    //   console.log('response', response);
    //
    //   return response;
    // }));
    //https://api-it.idcert.io/v1/courses?page=1&per_page=1000
    return this.httpClient.get<any>('https://api-it.idcert.io/v1/courses', {params: {page: 1, per_page: 1000}}).pipe(map(response => {
        console.log('response', response);

        return response;
    }));
  }

  getParams(page: number = 1, perPage: number = environment.itemsPerPage, sort: ITableSortSelected, filter: ITableFilter = null, list: boolean = false) {
    return this.authenticationService.getParams(page, perPage, sort, filter, list);
  }

  //Public
  getVersion() {
    return this.httpClient.get(this.baseUrl + '/version').pipe(map(response => {
      let r = new IResponse().deserialize(response);
      this.version = r.data;

      return r;
    }));
  }

  //Private

  //Location
  getRegions(page: number = 1, perPage: number = 1000) {
    let params = new HttpParams();
    params = params.append('perPage', String(perPage));
    params = params.append('page', String(page));

    return this.httpClient.get(environment.apiUrl + '/locations/regions', {params: params}).pipe(map(response => {
      let res = new IResponse().deserialize(response);
      this.regions = [];
      if (res.status) {
        this.regions = res.data;
      }

      return res;
    }));
  }

  getProvinces(region_id: string, page: number = 1, perPage: number = 1000) {
    let params = new HttpParams();
    params = params.append('perPage', String(perPage));
    params = params.append('page', String(page));

    return this.httpClient.get(environment.apiUrl + '/locations/regions/' + region_id + '/provinces', {params: params});
  }

  getCities(region_id: string, province_id: string, page: number = 1, perPage: number = 1000) {
    let params = new HttpParams();
    params = params.append('perPage', String(perPage));
    params = params.append('page', String(page));

    return this.httpClient.get(environment.apiUrl + '/locations/regions/' + region_id + '/provinces/' + province_id + '/cities', {params: params});
  }

  getCity(region_id: string, province_id: string,city_id: string) {
    return this.httpClient.get(environment.apiUrl + '/locations/regions/' + region_id + '/provinces/' + province_id + '/cities' + city_id);
  }

  getCitiesSearch(keyword: string, page: number = 1) {
    let params = new HttpParams();
    params = params.append('keyword', String(keyword));
    params = params.append('page', String(page));

    return this.httpClient.get(environment.apiUrl + '/locations/cities/search', {params: params});
  }
}
