<div *ngIf="mainTitle != ''" class="font-semi-bold md:text-xl mb-3"
      [ngClass]="{'font-bold text-sm md:text-base mb-0': small}">
  <span>{{mainTitle | translate}}</span>
</div>

<div class="group-container">
  <div *ngFor="let conf of configurations ; let i = index" class="col-span-full"
        [ngClass]="{'box': showGroupBox,
                    'sm:col-span-1': conf.groupLength == 1 && conf.isGroupNumbers, 'sm:col-span-2': conf.groupLength == 2 && conf.isGroupNumbers,
                    'sm:col-span-3': conf.groupLength == 3 && conf.isGroupNumbers, 'sm:col-span-4': conf.groupLength == 4 && conf.isGroupNumbers,
                    'sm:col-span-5': conf.groupLength == 5 && conf.isGroupNumbers,
                    'lg:col-span-1': conf.groupLength == 1 && !conf.isGroupNumbers, 'lg:col-span-2': conf.groupLength == 2 && !conf.isGroupNumbers,
                    'lg:col-span-3': conf.groupLength == 3 && !conf.isGroupNumbers, 'lg:col-span-4': conf.groupLength == 4 && !conf.isGroupNumbers,
                    'lg:col-span-5': conf.groupLength == 5 && !conf.isGroupNumbers}">
    <div *ngIf="!conf.isHidden"
      >
      <detail-form [id]="'df-' + conf.key" [item]="item" [isReadOnly]="isReadOnly || conf?.isReadOnly" [submitText]="submitText"
        [markOnTouched]="markOnTouched" [isNew]="isNew" [autoCompletePassword]="autoCompletePassword"
        [selectsLists]="selectsLists" [excludeDisabledItemsOnUpdate]="excludeDisabledItemsOnUpdate"
        [showUpDownNumberAlways]="showUpDownNumberAlways" [hideUpDownNumberAlways]="hideUpDownNumberAlways"
        [removeHiddenControls]="removeHiddenControls" [small]="small" [autofocus]="autofocus"
        [title]="conf?.title" [showFooter]="false" [configurations]="conf?.details" [inGroup]="true"
        (submit)="onSubmit($event, conf.key)"
        (selectChanged)="onSelectChange($event)" (inputChanged)="onInputChange($event)">
      </detail-form>
    </div>
  </div>

</div>

<div #ref><ng-content select="[footer]"></ng-content></div>
<ng-container *ngIf="!ref?.hasChildNodes() && !isReadOnly">
  <div class="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-6">
    <div class="sm:col-start-2 sm:col-span-2">
      <custom-button cssType="btn-primary" [width]="eCustomButtonWidth.full"
                      [text]="'Salva'" (onClick)="save()"></custom-button>
    </div>
  </div>
</ng-container>
