import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IResponse, Login} from '../models/response.model';
import {catchError, first, map} from 'rxjs/operators';
import {EventService} from './events.service';
import {IRequestRegister} from "../models/requests.model";
import {IUser} from "../models/data.model";
import {toggleModal} from "../../custom-component-lib/classes/shared-functions";
import {ITableFilter, ITableSortSelected} from "../../custom-component-lib/classes/interfaces";
import {eTableSortStatus} from "../../custom-component-lib/classes/enums";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  currentEmail: string;
  isLoggedIn = false;

  private currentUserSubject: BehaviorSubject<Login>;
  private apiError: BehaviorSubject<string>;

  constructor(private httpClient: HttpClient,
              private eventService: EventService) {
    this.apiError = new BehaviorSubject<string>('');
    this.setCurrentUser();
  }

  public get currentUserValue(): Login {
    this.setCurrentUser();

    return this.currentUserSubject.value;
  }

  get currentUser(): IUser {
    return this.currentUserValue.user;
  }

  login(email: string, password: string) {
    let body = {email: email, password: password};

    return this.httpClient.post<any>(`${environment.apiUrl}/auth/login`, body, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        let res = new IResponse().deserialize(response);

        if (res.status) {
          let login = new Login().deserialize(response.data);
          localStorage.setItem('currentUser', JSON.stringify(login));
          this.currentUserSubject.next(login);
        }

        this.setLoginState(res.status);

        return response;
      }));
  }

  loginSetFromSocial(token: string) {
    localStorage.setItem('currentUser', token);
    this.currentUserSubject.next(<Login> JSON.parse(token));
    this.setLoginState(true);
  }

  logout() {
    let body = {closeAll: 0};

    return this.httpClient.post<any>(`${environment.apiUrl}/auth/logout`, body, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {

        this.logoutAction();
        return new IResponse().deserialize(response);
      }), catchError((err, caught) => {
        this.clearDataFromLocalStorage();
        return [];
      }));
  }

  logoutAction() {
    this.clearDataFromLocalStorage();
    this.currentUserSubject.next(null);
    this.setLoginState(false);
  }

  register(body: IRequestRegister) {
    return this.httpClient.post<any>(`${environment.apiUrl}/auth/register`, body, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        // console.log('register response', response);
        let res = new IResponse().deserialize(response);

        if (res.status) {
          let login = new Login().deserialize(response.data);
          localStorage.setItem('currentUser', JSON.stringify(login));
          this.currentUserSubject.next(login);
          // localStorage.setItem('email', email);
        }

        this.setLoginState(res.status, false);

        return response;
      }));
  }

  confirmEmail(email: string, code: string) {
    let body = {email: email, code: code};

    return this.httpClient.post<any>(`${environment.apiUrl}/auth/confirm`, body, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        // console.log('confirm response', response);
        let res = new IResponse().deserialize(response);
        if (res.status) {
        }
        return res;
      }));
  }

  verificationNotification() {
    return this.httpClient.post<any>(`${environment.apiUrl}/auth/verification-notification`, {}, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        // console.log('verificationNotification response', response);
        let res = new IResponse().deserialize(response);

        return res;
      }));
  }

  forgotPassword(email: string) {
    let body = {email: email};

    return this.httpClient.post<any>(`${environment.apiUrl}/auth/forgot`, body, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        // console.log('forgotPassword response', response);
        let res = new IResponse().deserialize(response);

        return res;
      }));
  }

  resetPassword(code: string, email: string, password: string, passwordConfirmation: string) {
    let body = {token: code, email: email, password: password, password_confirmation: passwordConfirmation};

    return this.httpClient.post<any>(`${environment.apiUrl}/auth/reset`, body, {
      headers: this.getHeaders()
    })
      .pipe(map(response => {
        let res = new IResponse().deserialize(response);

        if (res.status) {
          // let login = new Login().deserialize(response.data);
          // localStorage.setItem('currentUser', JSON.stringify(login));
          // this.currentUserSubject.next(login);
          // localStorage.setItem('email', email);
        }

        // this.setLoginState(res.status, false);

        return response;
      }));
  }

  socialAuthentication(driver: string) {
    return environment.apiUrl + '/auth/social/redirect/?driver=' + driver;
  }

  private setCurrentUser() {
    this.currentUserSubject = new BehaviorSubject<Login>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentEmail = localStorage.getItem('email');
    this.isLoggedIn = this.currentUserSubject.value != null;
  }

  private setLoginState(value: boolean, fromLogin: boolean = true) {
    this.isLoggedIn = value;

    if (fromLogin) {
      this.eventService.onLoginStateChange(this.isLoggedIn);
    }
  }

  private clearDataFromLocalStorage() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('email');
    localStorage.removeItem('current-company');
  }

  private getHeaders() {
    // let partnerKey = environment.partnerkey;
    // return new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
    //   'Partner-Key': partnerKey
    // });

    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });
  }

  getParams(page: number = 1, perPage: number = environment.itemsPerPage, sort: ITableSortSelected, filter: ITableFilter = null, list: boolean = false) {
    let params = new HttpParams();
    params = params.append('perPage', String(perPage));
    params = params.append('page', String(page));
    if (sort) {
      params = params.append('sortField', sort.key);
      params = params.append('sortDirection', (sort.status == eTableSortStatus.asc ? 'asc' : 'desc'));
    }
    if (filter) {
      params = params.append('filterField', filter.filterField);
      params = params.append('filterValue', filter.filterValue);
      params = params.append('filterOperator', filter.filterOperator);
    }

    if (list) {
      params = params.append('list', true);
    }

    return params;
  }

  //ERRORS
  showError(error: string) {
    this.apiError.next(error);
    toggleModal('api-error-popup', true);
  }

  getApiError(): Observable<string> {
    return this.apiError.asObservable();
  }
}
