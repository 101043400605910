import { Component, OnInit, ViewChild } from '@angular/core';
import {DataService} from '../../../services/data.service';
import {Events, EventService} from '../../../services/events.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {Subscription} from 'rxjs';
import {ICompany, IDashboard, IDashboardDeadlines, IDashboardRequest, IPlanner, IProject, IProjectSmall} from "../../../models/data.model";
import {CompaniesService} from "../../../services/companies.service";
import { IResponse } from 'src/app/models/response.model';
import { ActivatedRoute } from '@angular/router';
import { IDetailFormConfiguration, IDetailsFormDataUpdate, ITableColConfigurations, ITableConfiguration } from 'src/custom-component-lib/classes/interfaces';
import { environment } from 'src/environments/environment';
import { eDetailFormInputType, eDetailFormType, eTableItemType } from 'src/custom-component-lib/classes/enums';
import { DetailFormComponent } from 'src/custom-component-lib/detail-form/detail-form.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('filter') filter?: DetailFormComponent;

  subscriptions: Subscription[] = [];
  isLoggedIn: boolean = false;
  version: string = '';
  companyInfo: ICompany;

  dashboard: IDashboard = null;

  env = environment;

  filterConfig: IDetailFormConfiguration[] = [];
  filterData: IDashboardRequest;

  deadlinesConfiguration: ITableConfiguration;
  deadlinesPage: number = 1;
  deadlinesItemsPerPage: number = environment.itemsPerPage;
  deadlinesTotalItems: number = 0;

  plannersConfiguration: ITableConfiguration;
  plannersPage: number = 1;
  plannersItemsPerPage: number = environment.itemsPerPage;
  plannersTotalItems: number = 0;

  projectsConfiguration: ITableConfiguration;
  projectsPage: number = 1;
  projectsItemsPerPage: number = environment.itemsPerPage;
  projectsTotalItems: number = 0;

  tasksConfiguration: ITableConfiguration;
  tasksPage: number = 1;
  tasksItemsPerPage: number = environment.itemsPerPage;
  tasksTotalItems: number = 0;

  constructor(private authenticationService: AuthenticationService,
              private dataService: DataService,
              private eventService: EventService,
              private companiesService: CompaniesService,
              private route: ActivatedRoute,) {

    this.attachEvent();
  }

  ngOnInit(): void {
    this.initDashboard();

    if (this.isLoggedIn) {
      this.parseResponse(this.route.snapshot.data['dashboard']);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  private parseResponse(response: any) {
    let res = <IResponse>response;

    if (res.status) {
      this.dashboard = res.data;

      this.deadlinesTotalItems = this.dashboard.deadlines.length;
      this.plannersTotalItems = this.dashboard.planners.length;
      this.projectsTotalItems = this.dashboard.projects.length;
    }
  }

  private getData() {
    this.companiesService.getDashboard(this.filterData).subscribe(response => {
      this.parseResponse(response);
    });
  }

  private attachEvent() {
    this.subscriptions.push(this.eventService.on(Events.loginStateChanged, (value) => {
      this.isLoggedIn = value;
      this.setUserInfo();
    }));

    this.subscriptions.push(this.eventService.on(Events.companySelectedChanged, (value) => {
      this.setCompanyInfo();
    }));

    this.isLoggedIn = this.authenticationService.isLoggedIn;
    this.version = this.dataService.version;

    this.setUserInfo();
    this.setCompanyInfo();
  }

  private setUserInfo() {
    this.companyInfo = null;
  }

  private setCompanyInfo() {
    this.companyInfo = this.companiesService.selectedCompany;
  }

  private initDashboard() {
    let cols: ITableColConfigurations[] = [
      {key: 'expired_at', label: 'Expired At', type: eTableItemType.date,
       dateOptions: {showOnlyDate: true, startFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'"}, wordWrapDisabled: true},
      {key: 'description', label: 'Description', type: eTableItemType.text},
      {key: 'project->name', label: 'Project Name', type: eTableItemType.text},
      {key: 'project->status', label: 'Project Status', type: eTableItemType.text},
      {key: 'project->type', label: 'Project Type', type: eTableItemType.text}
    ];
    this.deadlinesConfiguration = {editEnabled: false, deleteEnabled: false, downloadEnabled: false, thumbnailKey: '', cols: cols};

    cols = [
      {key: 'status', label: 'Status', type: eTableItemType.text},
      {key: 'type', label: 'Type', type: eTableItemType.text},
      {key: 'next_date', label: 'Expired At', type: eTableItemType.date,
       dateOptions: {showOnlyDate: true, startFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'"}, wordWrapDisabled: true},
      {key: 'operator->name', label: 'Operator', type: eTableItemType.text},
    ];
    this.plannersConfiguration = {editEnabled: false, deleteEnabled: false, downloadEnabled: false, thumbnailKey: '', cols: cols};

    cols = [
      {key: 'customer->name', label: 'Customer', type: eTableItemType.text},
      {key: 'type', label: 'Type', type: eTableItemType.text},
      {key: 'status', label: 'Status', type: eTableItemType.text},
      {key: 'expired_at', label: 'Expired At', type: eTableItemType.date,
       dateOptions: {showOnlyDate: true, startFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'"}, wordWrapDisabled: true}
    ];
    this.projectsConfiguration = {editEnabled: false, deleteEnabled: false, downloadEnabled: false, thumbnailKey: '', cols: cols};

    cols = [
      {key: 'task_type->name', label: 'Task Type', type: eTableItemType.text},
      {key: 'operator->name', label: 'Operator', type: eTableItemType.text}
    ];
    this.tasksConfiguration = {editEnabled: false, deleteEnabled: false, downloadEnabled: false, thumbnailKey: '', cols: cols};

    this.filterConfig = [
      { key: 'expiredInDeadlines', label: 'Deadlines - Expire in', type: eDetailFormType.input, inputType: eDetailFormInputType.number,
        number: { min: 0 }, defaultValue: 10, validationRequired: true, colLength: 3 },
      { key: 'expiredInPlanners', label: 'Planners - Expire in', type: eDetailFormType.input, inputType: eDetailFormInputType.number,
        number: { min: 0 }, defaultValue: 10, validationRequired: true, colLength: 3 },
      { key: 'expiredInProjects', label: 'Projects - Expire in', type: eDetailFormType.input, inputType: eDetailFormInputType.number,
        number: { min: 0 }, defaultValue: 10, validationRequired: true, colLength: 3 },
      { key: 'expiredInTasks', label: 'Tasks - Expire in', type: eDetailFormType.input, inputType: eDetailFormInputType.number,
        number: { min: 0 }, defaultValue: 10, validationRequired: true, colLength: 3 }
    ];
  }

  submit() {
    this.filter.onSubmit();
  }

  filterUpdate(data: IDetailsFormDataUpdate) {
    this.filterData = data.items as IDashboardRequest;

    this.getData();
  }

  deadlinesOnItemPerPageChange(v: number) {
    this.deadlinesPage = 1
    this.deadlinesItemsPerPage = v;
  }

  deadlinesOnItemSelected(item: IDashboardDeadlines) {
    console.log('deadlinesOnItemSelected', item)
  }

  plannersOnItemPerPageChange(v: number) {
    this.plannersPage = 1
    this.plannersItemsPerPage = v;
  }

  plannersOnItemSelected(item: IPlanner) {
    console.log('plannersOnItemSelected', item)
  }

  projectsOnItemPerPageChange(v: number) {
    this.projectsPage = 1
    this.projectsItemsPerPage = v;
  }

  projectsOnItemSelected(item: IProjectSmall) {
    console.log('projectsOnItemSelected', item)
  }

  tasksOnItemPerPageChange(v: number) {
    this.tasksPage = 1
    this.tasksItemsPerPage = v;
  }

  tasksOnItemSelected(item: IProjectSmall) {
    console.log('tasksOnItemSelected', item)
  }
}
