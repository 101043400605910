import { Pipe, PipeTransform } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { ITableColConfigurations } from "../classes/interfaces";
import { GetTableValuePipe } from "./gettablevaluepipe";
import { eValueType } from "../classes/enums";

@Pipe({ name: 'getStatusTooltip' })
export class GetStatusTooltipPipe implements PipeTransform {
  constructor(private getTableValuePipe: GetTableValuePipe) {
  }

  transform(item: any, conf: ITableColConfigurations): SafeHtml {
    let value = this.getTableValuePipe.transform(item, conf);
    let ret = conf.tooltipCustomText ? conf.tooltipCustomText : '';

    if (conf.iconStatus) {
      if (conf.iconStatus.statuses?.length > 0) {
        for (let s of conf.iconStatus.statuses) {
          switch (conf.iconStatus.valueType) {
            case eValueType.string:
              if (String(s.value) === value && s.tooltip) {
                return s.tooltip;
              }
              break;
            case eValueType.number:
              if (Number(s.value) === value && s.tooltip) {
                return s.tooltip;
              }
              break;
            case eValueType.boolean:
              if (Boolean(s.value) === value && s.tooltip) {
                return s.tooltip;
              }
              break;
            default:
              break;
          }
        }
      }
    }

    return ret;
  }
}
